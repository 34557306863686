import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, Skeleton } from '@mui/material';
import { SearchBox } from 'fragments';
import { Svg, Images } from 'assets';
import { homeActions } from 'store';
import { FindLoad } from '../../../utils';

export const Discover = () => {
    const dispatch = useDispatch();
    const refTimer = useRef();
    const [progress, setProgress] = useState(0);
    const [index, setIndex] = useState(null);
    const [isRunning, setIsRunning] = useState(true);
    const loader = FindLoad('GET_EVENTS_BUSINESS_NONPROFIT_HOME')
    const { eventBusinessNonProfit } = useSelector((state) => ({
        eventBusinessNonProfit: state?.homeStore?.eventBusinessNonProfit,
    }));

    useEffect(() => {
        dispatch(homeActions.getEventBusinessNonProfit());
    }, [dispatch]);

    useEffect(() => {
        if (eventBusinessNonProfit.length > 0 && index === null) {
            setIndex(0);
        }
    }, [eventBusinessNonProfit, index]);

    useEffect(() => {
        if (eventBusinessNonProfit?.length > 0) {
            const transitionTime = 5000;
            const progressUpdateInterval = 50;

            if (isRunning) {
                let startTime = Date.now();
                if (eventBusinessNonProfit.length > 1) {
                    refTimer.current = window.setInterval(() => {
                        const elapsedTime = Date.now() - startTime;
                        setProgress(Math.min((elapsedTime / transitionTime) * 100, 100));
                        if (elapsedTime >= transitionTime) {
                            setIndex((prevIndex) => (prevIndex + 1) % eventBusinessNonProfit.length);
                            startTime = Date.now();
                            setProgress(0);
                        }
                    }, progressUpdateInterval);
                }
            }
        }
        return () => {
            window.clearInterval(refTimer.current);
        };
    }, [isRunning, eventBusinessNonProfit]);


    const toggleTimer = () => {
        setIsRunning((prev) => !prev);

        if (isRunning) {
            setProgress(100);
            window.clearInterval(refTimer.current);
        } else {
            setProgress(0);
        }
    };

    return (
        <div className='discover-section'>
            <div className='search-wrapper'>
                <div className='search-box-banner'>
                    <p className='search-box-title'>
                        Discover Armenian Events, Businesses, Nonprofits
                    </p>
                    <p className='search-box-description'>
                        Find upcoming events, businesses, and nonprofits in your area!
                    </p>

                    <div className='search-box-categories'>
                        <SearchBox />

                        {/*<div className='tags-box'>*/}
                        {/*    {categories?.map((category, k) => (*/}
                        {/*        <button className='tag' key={k}>*/}
                        {/*            {category}*/}
                        {/*        </button>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <div className='slider-wrapper-box'>
                {loader?.length ?
                    <Skeleton className="slider-skeleton-loader" variant="rectangular" />
                    :
                <div className='slider-wrapper' style={{ backgroundImage: `url(${eventBusinessNonProfit?.[index]?.image})` }}>
                    <div className='slider-types-box'>
                        {eventBusinessNonProfit?.map((typeItem, idx) => (
                            <div key={idx} className='slider-type'>
                                <p style={index === idx ? { fontWeight: '700' } : {}}>{typeItem?.title}</p>
                                {index === idx ? (
                                    <LinearProgress
                                        color='inherit'
                                        variant='determinate'
                                        value={eventBusinessNonProfit?.length > 1 ? progress : 100}
                                    />
                                ) : (
                                    <div className={index > idx ? 'liner-done' : 'liner-dont-done'} />
                                )}
                            </div>
                        ))}

                        {eventBusinessNonProfit?.length > 1 &&
                            <button onClick={toggleTimer}>
                                {isRunning ? <Svg.StopBtn /> : <Svg.PlaySvg />}
                            </button>
                        }
                    </div>
                </div>
                }
            </div>
        </div>
    );
};
