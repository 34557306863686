import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Tab from '@mui/material/Tab';
import { Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { OrgImages } from './orgImages';
import { HoursBox } from './hoursBox';
import { OrgReviews } from './orgReviews';
import { OrgContacts } from './orgContacts';
import { OrgDetails } from './orgDetails';
import { FindSuccess, useModal } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';
import { EDIT_ORG_SOCIAL, EDIT_ORG_TYPE } from './constants';
import { Svg } from 'assets';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const EditOrg = ({ index }) => {
    const [value, setValue] = useState(index ? index : 0);
    const success = FindSuccess(EDIT_ORG_TYPE);
    const successOrgEdit = FindSuccess(EDIT_ORG_SOCIAL);
    const dispatch = useDispatch();
    const { close } = useModal();
    // const { org } = useSelector((state) => ({
    //     org: state.orgs.organizationsById,
    // }));
    // const adminCheck = userInfo?.auth?.role === 'ADMIN';
    // const managerInfo = adminCheck === true ? true : org?.manager?.id === userInfo.id;

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ORG_TYPE));
            close();
        }
        if (successOrgEdit) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ORG_SOCIAL));
            close();
        }
    }, [success, successOrgEdit]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && <div>{children}</div>}
            </div>
        );
    }

    return (
        <div className='edit-org-modal'>
            <div className='suggest-titles-icon'>
                <div className='suggest-svg-block'>
                    <Svg.SuggestSvg />
                </div>

                <div className='suggest-title_description'>
                    <p className='edit-org-modal-title'>Suggest a Business/Organization</p>
                    <p className='edit-org-modal-description'>Create your business/organization in less than 5
                        minutes.</p>
                </div>

            </div>

            <div className='edit-org-modal-box'>
                <Box className='edit-org-tabs' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                        <Tab label='Basic Info' {...a11yProps(0)} />
                        <Tab label='Images' {...a11yProps(1)} />
                        <Tab label='Hours' {...a11yProps(2)} />
                        <Tab label='Reviews' {...a11yProps(3)} />
                        <Tab label='Contact Us' {...a11yProps(4)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <OrgDetails />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    <OrgImages />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <HoursBox />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <OrgReviews />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <OrgContacts />
                </CustomTabPanel>
            </div>
        </div>
    );
};
