import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import { ORG_TYPES, OrgList } from 'fragments';
import { Svg } from 'assets';
import { organizationActions } from 'store';
import { FindLoad } from 'utils';

const ACTION_TYPE = 'GET_BUSINESSES';
export const BusinessNearYou = () => {
    const dispatch = useDispatch();
    const { businesses, orgCategories } = useSelector((state) => state.orgs);
    const [selectedTag, setSelectedTag] = useState('View all');
    const catList = orgCategories?.length ? ['View all', ...orgCategories?.slice(0, 3)] : ['View all'];
    const loader = FindLoad(ACTION_TYPE);

    useEffect(() => {
        dispatch(organizationActions.getOrgCategories());
    }, [dispatch]);


    useEffect(() => {
        const params = {
            limit: 10,
            type: ORG_TYPES.BUSINESS,
        };

        if (selectedTag && selectedTag !== 'View all') {
            params.categories = [selectedTag?.id];
        } else {
            delete params.categories;
        }
        dispatch(organizationActions.getBusinesses(params));
    }, [dispatch, selectedTag]);

    return (
        <div className="business-near-you-wrapper">
            <div className="business-near-you container">
                <div className="business-near-you-info">
                    <p className="business-near-you-title">
                        Discover Popular Businesses Near You
                    </p>

                    <p className="business-near-you-desc">
                        Discover and support non-profit organizations dedicated to making a difference in the Armenian
                        community.
                    </p>
                    <div>
                        <div className="view-all-btn desktop-view">
                            <NavLink to={'/businesses'}>
                                Browse All Organizations
                                <Svg.ArrowUpRight />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="business-near-you-cards">
                    <div className="business-near-you-categories">
                        <div className="tag-tabs-box">
                            {catList?.map((i, j) => (
                                <Button key={j} onClick={() => setSelectedTag(i)}
                                        className={selectedTag === i ? 'active-tab' : 'passive-tab'}>
                                    {i?.text || i}
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className="business-near-you-organizations">
                        {loader?.length ?
                            <React.Fragment>
                                <Skeleton className="skeleton-card" variant="rectangular" />
                                <Skeleton className="skeleton-card" variant="rectangular" />
                                <Skeleton className="skeleton-card" variant="rectangular" />
                            </React.Fragment>
                            :
                            businesses?.orgs?.map((i, j) => (
                                <React.Fragment key={j}>
                                    <OrgList viwButton="mapView" org={i} />
                                </React.Fragment>
                            ))}
                    </div>
                </div>

                <div className="flex-end mobile-view">
                    <div className="view-all-btn mobile-view">
                        <NavLink to={'/businesses'}>
                            Browse All Organizations
                            <Svg.ArrowUpRight />
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};
