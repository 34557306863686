import React, { useEffect } from 'react';
import { OrgList } from 'fragments';
import { useDispatch, useSelector } from 'react-redux';
import { organizationActions } from 'store';
import { FindLoad } from 'utils';
import { Skeleton } from '@mui/material';

const ACTION_TYPE = 'GET_ORGANIZATION';

export const SimilarOrgs = () => {
    const { org, organizations } = useSelector((state) => ({
        org: state.orgs.organizationsById,
        organizations: state.orgs.organizations,
    }));
    const dispatch = useDispatch();
    const orgList = organizations?.orgs?.filter((item) => item?.id !== org?.id);
    const loader = FindLoad(ACTION_TYPE);

    useEffect(() => {
        if (org?.categories?.length > 0) {
            const newArr = [];
            org?.categories?.forEach((category) => {
                newArr.push(category?.id);
            });

            const params = {
                categories: newArr,
            };
            dispatch(organizationActions.getOrg(params));
        }
    }, [org]);

    return (
        <div>
            {!!orgList?.length && (
                <div className="org-sections-wrapper">
                    <div className="org-sections-box">
                        <p className="org-detail-sections-title">Discover Similar Organizations</p>
                    </div>
                    <div className="similar-orgs">

                        {loader?.length ?
                            <Skeleton className="org-skeleton" variant="rectangular" />
                            :
                            orgList?.map((org, i) => (
                                <div key={i}>
                                    <OrgList org={org} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            )}
        </div>
    );
};
