import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Svg } from 'assets';
import { mapStyles } from './constants';

const mapStyle = {
    width: '100%',
    height: '400px',
    position: 'relative',
    borderRadius: '8px 8px 0 0',
};
const SingleMap = (props) => {
    return (
        <div style={{ padding: 0 }}>
            <div>
                <Map
                    google={props.google}
                    zoom={12}
                    styles={mapStyles}
                    style={mapStyle}
                    initialCenter={{
                        lat: props?.address?.lat,
                        lng: props?.address?.lng,
                    }}
                    containerStyle={mapStyle}
                    streetViewControl={false}
                    fullscreenControl={false}
                    mapTypeControl={false}
                >
                    <Marker
                        position={{
                            lat: props?.address?.lat,
                            lng: props?.address?.lng,
                        }}
                        icon={Svg.RedMapMarker}
                    />
                </Map>
                {/*{props?.address?.formattedAddress && (*/}
                {/*    <div className="map-details">*/}
                {/*        <p className="community">{props?.address?.city}</p>*/}
                {/*        <p className="formatted-address">{props?.address?.formattedAddress}</p>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(SingleMap);
