import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageLoader } from 'components';
import { FindLoad } from 'utils';
import { CardInformation, InvoicesMobile } from './fragments';
import { Invoices } from './fragments/invoices';
import { paymentActions } from 'store';
import { Svg } from '../../assets';
import BasicDocument from './fragments/pdf';

const ACTION_TYPE = 'GET_CURRENT_CARD';
export const Billing = () => {
    const loader = FindLoad(ACTION_TYPE);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    useEffect(() => {
        dispatch(paymentActions.getCurrentCard());
        dispatch(paymentActions.getInvoices());
    }, [dispatch]);

    if (loader) {
        return <PageLoader />;
    }

    return (
        <div>
            <div className="billing-page">
                <p className="page-global-title">Billing Info</p>
                <div className="card-information-section">
                    <CardInformation />
                    <div className="tablet-and-desktop-view">
                        <Invoices setShow={setShow} />
                    </div>
                </div>
                <div className="mobile-view">
                    <InvoicesMobile setShow={setShow} />
                </div>
            </div>
            {show && (
                <div className="pdf-reader">
                    <div style={{ padding: '12px 20px' }}>
                        <button className="close-pdf" onClick={() => setShow(null)}>
                            <Svg.CloseBurger />
                        </button>

                    </div>
                    <BasicDocument invoice={show} />
                </div>
            )}
        </div>
    );
};
