import { makeStyles } from '@mui/styles';

export const buyTicketStyles = makeStyles(() => ({
    addCardForm: {
        width: '100%',
        height: '100%',
        marginTop: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    spaceBetween: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    // .MuiPaper-root-MuiAccordion-root
    buyTicketWrapper: {
        width: '100%',
        height: 'auto',
        // marginTop: '-32px',
        overflow: 'auto',
        background: 'white',
        borderRadius: '8px',

        '@media (min-width: 375px)': {
            width: '350px',
        },

        '@media (min-width: 767px)': {
            width: '718px',
            borderRadius: 0,
            // height: '700px',
        },
        '@media (min-width: 1279px)': {
            width: '1259px',
            maxWidth: '100%',
            display: 'flex',
        },
    },
    ticketCategoryWrapper: {
        // padding: '0 16px 24px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'auto',
        // '@media (min-width: 768px)': {
        //     padding: '0 40px 40px',
        // },
        '@media (min-width: 1279px)': {
            // borderRight: '1px solid #E1E1E1',
            // padding: '40px',
            width: '60%',
        },
    },

    choseTicketTitle: {
        color: '#181D27',
        fontSize: '18px',
        fontWeight: '600',
        '@media (min-width: 768px)': {
            fontSize: '22px',
        },
        '@media (min-width: 1279px)': {
            fontSize: '24px',
        },
    },
    bigTicketTitle: {
        color: '#222',
        fontSize: '28px',
        fontWeight: '700',
        // '@media (min-width: 768px)': {
        //     fontSize: '22px',
        // },
        // '@media (min-width: 1279px)': {
        //     fontSize: '28px',
        // },
    },

    ticketsWrapper: {
        maxHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        gap: '16px',
        marginTop: '24px',
        // margin: '16px 0',

        // '@media (min-width: 768px)': {
        //     height: '609px',
        //     marginTop: '40px',
        // },

        '@media (min-width: 1279px)': {
            // maxHeight: '575px',
            marginTop: '40px',
        },
    },

    ticketsBox:{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },

    ticketInfoWrapper: {
        '& .MuiAccordion-root:before': {
            display: 'none',
            backgroundColor: 'transparent !important',
        },
    },

    accordionWrapper: {
        borderRadius: '8px !important',
        border: '1px solid #E9EAEB',
        overflow: 'hidden',

        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto',
        },
        '& .MuiAccordion-root:before': {
            display: 'none',
            backgroundColor: 'transparent !important',
        },

        '& .MuiPaper-elevation1': {
            boxShadow: 'unset',

        },
        '& .MuiAccordionSummary-root': {
            minHeight: 'auto',
            padding: '16px',
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
        },
    },

    accordionSummary: {
        borderBottom: '1px solid #F4F4F4',
        background: '#FFF !important',
        padding: '12px 8px',
        '@media (min-width: 767px)': {
            padding: '12px 16px',
        },
        '&.Mui-expanded': {
            background: '#F7F8FA !important',
        },
    },
    accordionSummaryWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // justifyContent: 'space-between',
        // alignItems: 'center',
    },
    titleAndPrice: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '16px',
    },
    showMoreAndCounter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    showMoreInfoBtn: {
        background: 'transparent',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        color: '#535862',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '20px',
        padding: '0',
        cursor: 'pointer',
        gap: '4px',
    },
    ticketTitle: {
        color: '#181D27',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
        marginRight: '16px',
        width: '100%',
    },
    ticketPrice: {
        color: '#85242D',
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '27px',
    },

    actionsStyle: {
        display: 'flex',
        '& button': {
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            border: '1px solid #E9EAEB',
            background: '#FFF',
            cursor: 'pointer',
            boxShadow: '0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
        },
    },

    inputStyle: {
        margin: '0 8px',
        width: '40px',
        height: '40px',
        display: 'flex',
        borderRadius: '4px',
        border: '1px solid #F4F4F4',
        background: '#FFF',
        padding: '0 8px',
        color: '#344054',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '20px',
        textAlign: 'center',
        outline: 'none',
        boxShadow: '0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
    },
    accessDescription: {
        color: '#494949',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '18px',
        marginTop: '8px',
    },
    ticketInformation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginTop: '16px',

        '@media (min-width: 767px)': {
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexDirection: 'row',
        },

        '& p': {
            color: '#BEBEBE',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
        },
    },

    ticketCountWrapper: {
        margin: '8px 0',
        '@media (min-width: 767px)': {
            margin: 0,
        },
    },

    ticketEventWrapper: {
        display: 'none',
        '@media (min-width: 1279px)': {
            width: '40%',
            background: '#F7F8FA',
            display: 'block',
            height: 'inherit',
            overflow: 'auto',
            padding: '32px',
            borderLeft: '1px solid #E9EAEB',
        },
    },
    closeButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    eventImageAndTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: '1px solid #E9EAEB',
        padding: '0 0 16px 0',
        '& img': {
            width: '80px',
            height: '80px',
            borderRadius: '8px',
            marginRight: '16px',
            objectFit: 'cover',
        },
    },
    orderSummary: {
        color: '#535862',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        marginTop: '8px',
    },
    eventTitle: {
        color: '#181D27',
        fontSize: '22px',
        fontWeight: '600',
        lineHeight: '32px',
        linHeight: 'normal',
        fontStyle: 'normal',
    },
    eventInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px 0',
        borderBottom: '1px solid #E9EAEB',
    },
    titleAndInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',

        '& p': {
            color: '#181D27',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            marginRight: '8px',
        },
        '& span': {
            color: '#181D27',
            textAlign: 'right',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
        },
    },
    continueBtnWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop:'24px',

        '@media (min-width: 767px)': {
            marginTop:'40px',
        },
    },
    continueBtn: {
        width: '107px',
        height: '44px',
        padding: '10px 18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '600',
        borderRadius: '8px',
        background: '#BB3240',
        border: 'none',
        cursor: 'pointer',
        boxShadow: '0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
        transition: 'background 0.2s',
        '&:hover': {
            background: '#85242D',
            transition: 'background 0.2s',
        },
    },
    completeBtn: {
        width: '183px',
        height: '44px',
        padding: '10px 18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '600',
        borderRadius: '8px',
        background: '#BB3240',
        whiteSpace: 'nowrap',
        border: 'none',
        cursor: 'pointer',

        '&:hover': {
            background: '#85242D',
        },

        '&:disabled': {
            background: '#E0A1A7',
        }
    },
    ticketCount: {
        fontWeight: 600,
        fontSize: '14px',
        margin: '0 4px',
    },
    selectTMethodTitle: {
        color: '#181D27',
        fontSize: '18px',
        fontWeight: '600',
    },

    selectTMethodDescription: {
        color: '#535862',
        fontSize: '14px',
        fontWeight: '400',
        marginTop: '8px',
    },
    paymentMethodWrapper: {
        width: '100%',
        height: '72px',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '8px',
        borderBottom: '1px solid #FAFAFA)',
        cursor: 'pointer',
    },
    paymentMethodIconTitle: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginLeft: '8px',
            color: '#222',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
            '@media (min-width: 768px)': {
                fontSize: '16px',
                marginLeft: '16px',
            },
        },
    },
    paymentTypeSelector: {
        marginTop: '24px',
    },
    paymentIcon: {
        width: '58px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    savedCardOr: {
        margin: '8px 0 32px',
        color: '#767676',
        fontSize: '16px',
        fontWeight: '400',
    },
    paymentDetailCardWrapper: {
        borderRadius: '8px',
        border: '1px solid #E1E1E1',
        cursor: 'pointer',
        width: '100%',
        padding: '16px 12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',

        '@media (min-width: 767px)': {
            padding: '16px',
        },
    },
    cardInformationWrapper: {
        width: '100%',
        marginLeft: '10px',
        '@media (min-width: 768px)': {
            marginLeft: '16px',
        },
    },
    cardTypeText: {
        color: '#181D27',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        fontFamily: 'Inter, sans-serif',
        marginBottom: '4px',
    },
    cardInformationText: {
        color: '#494949',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '24px',
        marginBottom: '4px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media (min-width: 768px)': {
            fontSize: '16px',
        },
    },
    flexAble: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    newCardWrapper: {
        borderRadius: '8px',
        border: '1px solid #E9EAEB',
        width: '100%',
        padding: '16px',
        marginBottom: '16px',
        height: 'auto',
        minHeight: '84px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    newCardWrapperHead: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    doneScreenWrapper: {
        width: '100%',
        // padding: '0 16px 16px',
        // '@media (min-width: 768px)': {
        //     padding: '0 40px 40px',
        // },
    },
    flexEnd: {
        width: '100%',
        justifyContent: 'flex-end',
        display: 'flex',
    },
    flexEndMobile: {
        width: '100%',
        justifyContent: 'flex-end',
        display: 'flex',
        '@media (min-width: 1279px)': {
            display: 'none',
        },
    },
    letterWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px ',
        borderBottom: '1px solid #F4F4F4',
        flexDirection: 'column',
        '@media (min-width: 768px)': {
            padding: '16px 32px',
            flexDirection: 'row',
        },
    },
    thanksTitle: {
        margin: '16px 0',
        color: '#181D27',
        fontSize: '18px',
        fontWeight: '700',
        '@media (min-width: 768px)': {
            fontSize: '24px',
            margin: '0 16px',
        },
        '@media (min-width: 1279px)': {
            fontSize: '24px',
        },
    },
    ticketEventInformationWrapper: {
        padding: '0 16px 16px',
        borderRadius: '8px',
        marginTop: '24px',

        '@media (min-width: 768px)': {
            padding: '0 16px 16px',
            marginTop: '32px',
        },
        '@media (min-width: 1279px)': {
            padding: '0 32px 32px',
        },
    },

    ticketEventInformationBox: {
        borderRadius: '16px',
        background: '#F7F8FA',
        padding: '24px',
    },
    goingTo: {
        marginBottom: '8px',
        color: '#535862',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '24px',
        '@media (min-width: 768px)': {
            fontSize: '18px',
            lineHeight: '27px',
        },
    },
    doneEventTitle: {
        color: '#181D27',
        fontSize: '16px',
        fontWeight: '700',
        textTransform: 'uppercase',
        '@media (min-width: 768px)': {
            fontSize: '24px',
        },
        '@media (min-width: 1279px)': {
            fontSize: '20px',
        },
    },
    title: {
        marginBottom: '4px',
        color: '#85242D',
        fontSize: '16px',
        fontWeight: '500',
        marginTop: '24px',
        '@media (min-width: 768px)': {
            marginTop: '32px',
        },
    },
    value: {
        color: '#181D27',
        fontSize: '16px',
        fontWeight: '500',
    },
    viewTickets: {
        margin: '40px auto 20px',
        width: '136px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        border: '1px solid #D5D7DA',
        background: '#FFF',
        color: '#344054',
        fontSize: '16px',
        fontWeight: '600',
        cursor: 'pointer',
        '@media (min-width: 768px)': {
            margin: '24px 0 0 0',
        },
        '&:hover': {
            background: '#FAFAFA',
        }
    },
    ticketCategoryEventTitle: {
        padding: '0 0 12px',
        borderBottom: '1px solid #E1E1E1',
        width: '100%',
        display: 'block',
        marginBottom: '24px',

        '@media (min-width: 767px)': {
            marginBottom: '40px',
        },
        '@media (min-width: 1279px)': {
            display: 'none',
        },
    },
    mobileEventTitle: {
        color: '#222',
        fontSize: '24px',
        fontWeight: '700',
        lineHeight: '32px',
        marginBottom: '8px',
        width: '100%',
    },
    mobileEventDate: {
        color: '#626262',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: 'auto',
        '@media (min-width: 767px)': {
            lineHeight: '32px',
        },
    },
}));

export const checkoutStyles = makeStyles(() => ({
    addCardTitle: {
        color: '#2A374E',
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '36px',
    },

    addCardForm: {
        marginTop: '32px',
    },
    cardTitle: {
        color: '#494949',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
    },
    cardInputWrapper: {
        width: '100%',
        height: '48px',
        padding: '12px',
        borderRadius: '8px',
        border: '1px solid #9D9D9D',
    },
    cardEmailInput: {
        width: '100%',
        height: '48px',
        padding: '12px',
        borderRadius: '8px',
        border: '1px solid #9D9D9D',

        '& input': {
            border: 'none',
            background: 'transparent',
            width: '100%',
            height: '100%',
            outline: 'none',
        },
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '16px',
    },
    acceptText: {
        color: '#535862',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
    },
    linkText: {
        color: '#535862',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        marginLeft: '4px',
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '33px',
    },
    addButton: {
        width: '100%',
        border: 'none',
        height: '48px',
        marginLeft: '16px',
        background: '#49B776',
        borderRadius: '8px',
        color: 'white',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
        cursor: 'pointer',
    },
    cancelButton: {
        width: '100%',
        border: 'none',
        height: '48px',
        background: '#F4F4F4',
        borderRadius: '8px',
        color: '#4B5C68',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
        cursor: 'pointer',
    },
    closeButton: {
        '& button': {
            position: 'absolute',
            marginTop: '-20px',
            marginRight: '-20px',
        },
    },
}));
