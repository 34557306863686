import { Svg } from 'assets';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteModal, StipeCard } from 'fragments';
import { useModal } from 'utils';
import { paymentActions } from 'store';

const DELETE_TYPE = 'UN_ASSIGN_CARD';
export const CardInformation = () => {
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const { currentCard } = useSelector((state) => ({
        currentCard: state?.payments?.currentCard,
    }));

    const deleteCard = () => {
        openModal(
            <DeleteModal
                actionType={DELETE_TYPE}
                title={'Delete Card'}
                description={'Are you sure you want to delete this card?'}
                onDelete={() => dispatch(paymentActions.deleteCard())}
            />
        );
    };

    const stripeCardModal = () => {
        openModal(<StipeCard />);
    };

    return (
        <div>
            <p className="payment-method-title">Payment Method</p>
            <div className="card-box">
                {currentCard ? (
                    <div className="current-card card">
                        <div className="space-between card-info">
                            <p>{`**** **** **** ${currentCard?.last4 || ''}`}</p>

                            <div className="card-type-svg-block">{currentCard?.card === 'visa' ? <Svg.Visa /> : <Svg.Master />}</div>
                        </div>

                        <p className="expiry-date">{`Expiry ${currentCard?.exp_month || ''}/${currentCard?.exp_year || ''}`}</p>

                        <div className="edit-delete">
                            <button className="delete" onClick={deleteCard}>
                                Delete
                            </button>
                            <button onClick={stripeCardModal} className="edit">
                                Edit
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="no-card-box card" onClick={stripeCardModal}>
                        <div className="no-card-box-svg-block">
                            <Svg.Add />
                        </div>

                        <p>Add Card</p>
                    </div>
                )}

                <div className="we-accept-box">
                    <p>We Accept</p>

                    <div className="we-accept-svg-block">
                        <Svg.MasterAcceptSvg />
                    </div>

                    <div className="we-accept-svg-block">
                        <Svg.VisaAcceptSvg />
                    </div>
                </div>
            </div>
        </div>
    );
};
