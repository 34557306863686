import React, { forwardRef, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Svg } from 'assets';

export const InputMain = forwardRef(
    (
        {
            customDesign = true,
            label,
            labelHeader,
            placeholder,
            rules,
            control,
            name,
            type = 'text',
            maxLength,
            startAdornment,
            endAdornment,
            className,
            onClickIconStart,
            onClickIconEnd,
            disabled,
            ...anotherProps
        },
        ref
    ) => {
        const [showPassword, setShowPassword] = useState(false);

        const typePassword = showPassword ? 'text' : 'password';
        const EndIcon = showPassword ? Svg.EyeSvg : Svg.EyeSlashSvg;

        return (
            <div className={`input-main-wrapper ${customDesign ? 'input-custom-wrapper' : ''} ${className ? className : ''}`} ref={ref}>
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field, formState: { errors } }) => (
                        <>
                            {labelHeader && (
                                <p className={`input-main-label-header ${errors[field?.name]?.message ? 'errorLabel' : ''}`}>
                                    {labelHeader}
                                    {rules?.required && <span className="input-main-label-header-required">*</span>}
                                </p>
                            )}
                            <TextField
                                disabled={disabled}
                                ref={ref}
                                {...anotherProps}
                                {...field}
                                autoComplete="on"
                                error={!!errors[field?.name]?.message}
                                type={showPassword ? typePassword : type}
                                value={field.value}
                                placeholder={placeholder}
                                label={label}
                                inputProps={{ maxLength: maxLength }}
                                InputProps={{
                                    startAdornment: startAdornment && (
                                        <InputAdornment
                                            onClick={onClickIconStart}
                                            className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}
                                            position="start">
                                            {startAdornment}
                                        </InputAdornment>
                                    ),
                                    endAdornment:
                                        (endAdornment && (
                                            <InputAdornment
                                                onClick={onClickIconEnd}
                                                position="end"
                                                className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}>
                                                {endAdornment}
                                            </InputAdornment>
                                        )) ||
                                        (type === 'password' && (
                                            <InputAdornment
                                                onClick={(e) => {
                                                    onClickIconEnd && onClickIconEnd(e);
                                                    setShowPassword((prev) => !prev);
                                                }}
                                                position="end"
                                                className={`${errors[field?.name]?.message ? 'error-svg-password' : ''}`}>
                                                <EndIcon className="error-svg-password" />
                                            </InputAdornment>
                                        )),
                                }}
                            />
                            <p className="error-message">{errors[field?.name]?.message}</p>
                        </>
                    )}
                />
            </div>
        );
    }
);

InputMain.displayName = 'InputMain';
