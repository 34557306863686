import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buyTicketStyles, checkoutStyles } from '../styles';
import { ErrMessage, MinLoader } from 'components';
import { httpRequestsOnErrorsActions, ticketActions } from 'store';
import { FindError, FindLoad } from 'utils';

const ACTION_TYPE = 'BUY_TICKET';
export const CompleteFree = ({ selectedTickets, eventId }) => {
    const { currentCard, accessToken } = useSelector((state) => ({
        accessToken: state.auth.accessToken,
    }));
    const cardClasses = checkoutStyles();
    const token = accessToken ? accessToken : typeof window !== 'undefined' && localStorage.getItem('access-token');
    const classes = buyTicketStyles();
    const [email, setEmail] = useState(null);
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let ticketList = [];
        selectedTickets?.map((i) =>
            ticketList.push({
                ticketId: i?.id,
                count: i?.count,
            })
        );
        dispatch(ticketActions.buyTicketNoToken({ tickets: ticketList, email: email, eventId: eventId }, eventId));
        ticketList = [];
    };

    return (
        <div className="ticket-category-box">
            <div className={classes.spaceBetween}>
                <form onSubmit={handleSubmit} className={classes.addCardForm}>
                    <div>
                        <p className={classes.choseTicketTitle}>Checkout</p>
                        <div className={classes.ticketsWrapper}>
                            <p className={classes.selectTMethodTitle}>Payment Details</p>

                            {currentCard?.card &&
                                <p className={classes.savedCardOr}>
                                    {currentCard?.card ? 'Choose Your Payment Method: Use Your Saved Card or Add a New One' : ''}
                                </p>
                            }

                            {!token && (
                                <>
                                    <p style={{ marginTop: '24px' }} className={cardClasses.cardTitle}>
                                        {'Email*'}
                                    </p>
                                    <div className={cardClasses.cardEmailInput}>
                                        <input type="email" required={true} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </>
                            )}
                            {backError?.error && <ErrMessage text={backError?.error} />}
                            <div className={classes.continueBtnWrapper}>
                                <button
                                    className={classes.completeBtn}
                                    type="submit"
                                    style={email === false ? { background: '#F4F4F4' } : {}}
                                    disabled={email === false}>
                                    {loader?.length ? <MinLoader color={'white'} position={'relative'} /> : 'Complete Payment'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
