import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Images } from 'assets';
import { LinksBoxDesktop } from './fragments/linksBoxDesktop';
import { authActions } from 'store';
import { RenderScroll, token } from 'utils';
import { RoutersInfo } from './fragments/constants';
import { SearchBox } from '../searchBox';
import { LinksBoxMobile } from './fragments/LinksBoxMobile';

export const Layout = () => {
    const [showSearch, setShowSearch] = useState(null);
    const [openingMenu, setOpeningMenu] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scrollPos = RenderScroll();

    useEffect(() => {
        if (token) {
            dispatch(authActions.getMyProfile());
            dispatch(authActions.getMyOrgs());
        }
    }, [dispatch, token]);

    const toggleMenu = (e) => {
        openingMenu ? setOpeningMenu(null) : setOpeningMenu(e?.currentTarget);
        setShowSearch(false);
    };

    const toggleSearch = () => {
        setShowSearch((prev) => !prev);
        setOpeningMenu(null);
    };

    return (
        <div className="layout-wrapper">
            <div className="layout-box">
                <div className="layout-box-wrapper">
                    <div className="header-logo-navigation">
                        <button className="header-logo-button" onClick={() => navigate('/')}>
                            <img src={Images.LogoImage} alt="logo" />
                        </button>

                        <nav className="header-navigation-desktop">
                            {RoutersInfo(!!token).map(({ title, path }, index) => (
                                <NavLink
                                    key={index}
                                    to={path}
                                    className={({ isActive }) => (isActive ? 'header-link active' : 'header-link')}>
                                    {title}
                                </NavLink>
                            ))}
                        </nav>
                    </div>


                    <div className="desktop-view">
                        <LinksBoxDesktop
                            showSearch={showSearch}
                            toggleSearch={toggleSearch}
                            openingMenu={openingMenu}
                            toggleMenu={toggleMenu}
                        />
                    </div>

                    <div className="mobile-and-tablet-view">
                        <LinksBoxMobile
                            showSearch={showSearch}
                            toggleSearch={toggleSearch}
                            openingMenu={openingMenu}
                            toggleMenu={toggleMenu}
                        />
                    </div>
                </div>

                {showSearch && scrollPos > 100 && <SearchBox fromLayout={true} />}
            </div>
        </div>
    );
};
