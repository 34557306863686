import React, { useEffect, useState } from 'react';
import { Loader } from 'components';
import { FileUploader } from 'react-drag-drop-files';
import { Svg } from 'assets';
import { orgStyles } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';


export const UploadImage = ({
                                handleChange,
                                handleClearImg,
                                imgSrc,
                                error,
                                handleSelectIndex,
                                eventInfo,
                                loaderUpload,
                                imgIndex,
                                type,
                            }) => {
    const classes = orgStyles();
    const [fileAdded, setFileAdded] = useState(null);
    const [slidesView, setSlidesView] = useState(1);

    useEffect(() => {
        const updateSlidesView = () => {
            if (window.innerWidth > 1289) {
                setSlidesView(3);
            } else if (window.innerWidth > 768) {
                setSlidesView(3);
            } else if (window.innerWidth > 375) {
                setSlidesView(2);
            } else {
                setSlidesView(1);
            }
        };

        updateSlidesView();
        window.addEventListener('resize', updateSlidesView);

        return () => {
            window.removeEventListener('resize', updateSlidesView);
        };
    }, []);

    const handleSetIndex = (i) => {
        handleSelectIndex(i);
    };

    const handleDelete = (j, i) => {
        handleClearImg(j, i);
        setFileAdded(null);
    };

    return (
        <div>
            <div>
                <div style={error === 'img' ? { borderColor: '#F07379' } : {}} className={classes.dragDropImage}>
                        <Swiper
                            slidesPerView={slidesView}
                            className={type === 'organization' ? classes.carouselWidthOrg : classes.carouselWidth}
                        >
                            {!!imgSrc?.length && (
                                imgSrc.map((i, j) => (
                                    <SwiperSlide key={j}>
                                        <div
                                            key={j}
                                            className={
                                                classes.selectedImageBorder
                                            }
                                            style={{
                                                position: 'relative',
                                                zIndex: '9',
                                                borderColor: j === imgIndex ? '#85242D' : 'transparent',
                                            }}>
                                            <div
                                                style={{ position: 'absolute', right: 5, top: 5, zIndex: 99 }}
                                                className={classes.CloseButtonContent}>
                                                <button className={classes.CloseButton}
                                                        onClick={() => handleDelete(j, i)}>
                                                    <Svg.Close />
                                                </button>
                                            </div>
                                            {j === imgIndex && (
                                                <div
                                                    className={
                                                        // eventInfo ? classes.mainImageEdit :
                                                        classes.mainImage
                                                    }>
                                                    Main Image
                                                </div>
                                            )}
                                            <div
                                                onClick={() => handleSetIndex(j)}
                                                key={j}
                                                className={eventInfo ? classes.editEventImage : classes.createEventImage}>
                                                <img src={i.url} alt='icon' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            )}

                        </Swiper>
                    <FileUploader
                        fileOrFiles={fileAdded}
                        multiple={true}
                        handleChange={async (file) => {
                            handleChange(file);
                            setFileAdded(file);
                        }}
                        name='file'>
                        <div className={classes.dragDropBody}>
                            {loaderUpload ? (
                                <Loader type='ThreeDots' color='#FFFFFF' height={16} width={16}
                                        style={{ margin: '10px', padding: '0' }} />
                            ) : (
                                <div>
                                    <Svg.Graphic />
                                </div>
                            )}

                            <p className={classes.dragDropBodyDrag}>

                                <div className={classes.custom}>
                                    <input style={{ display: 'none' }} />
                                    <i className='fa fa-cloud-upload' />
                                    Drag & Drop or <span>Select</span> Images
                                </div>
                            </p>
                            {error === true ? (
                                <p className={classes.dragDropBodyDragSizeError}>Max size must be 2 MB</p>
                            ) : (
                                <p className={classes.dragDropBodyDragSize}>Max size for each image 2 MB</p>
                            )}
                        </div>
                    </FileUploader>
                </div>
            </div>
        </div>
    );
};
