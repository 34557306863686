import { Svg } from 'assets';
import eventImage from 'assets/images/img/eventImage.png';
import orgImage from 'assets/images/img/footer.png';
import businessImage from 'assets/images/img/banner.png';

export const AboutBusinessList = [
    {
        icon: <Svg.EventsNearSvg />,
        title: 'Connect with Your Armenian Heritage',
        subTitle: 'Join our vibrant community to celebrate heritage and forge friendships.',
        link: '/events',
        cta: 'Find Events'
    },
    {
        icon: <Svg.ArmenianBusinessesSvg />,
        title: 'Discover and Attend',
        subTitle: 'Explore cultural, business, and community events near you.',
        link: '/businesses',
        cta: 'Explore Businesses'
    },
    {
        icon: <Svg.ArmenianOrganizationsSvg />,
        title: 'Support and Grow',
        subTitle: 'Promote your business, share your cause, and connect with others',
        link: '/nonprofits',
        cta: 'Get Involved'
    },
];

export const TabList = [
    {
        label: 'All',
        value: 'ALL',
    },
    {
        label: 'Art&Culture',
        value: 'ART',
    },
    {
        label: 'Expos',
        value: 'EXPOS',
    },
    {
        label: 'Music',
        value: 'MUSIC',
    },
];

export const DiscoveredList = [
    {
        label: 'View All',
        value: 'View All',
    },
    {
        label: 'Food',
        value: 'ART',
    },
    {
        label: 'Technology',
        value: 'EXPOS',
    },
    {
        label: 'Art',
        value: 'MUSIC',
    },
];

export const TagLists = [
    {
        id: 1,
        Icon: '🍿 ',
        title: 'Cinema',
    },

    {
        id: 2,
        Icon: '📜 ',
        title: 'Education',
    },

    {
        id: 3,
        Icon: '👜 ',
        title: 'Fashion',
    },

    {
        id: 4,
        Icon: '',
        title: 'tag 1',
    },

    {
        id: 5,
        Icon: '',
        title: 'tag 2',
    },

    {
        id: 6,
        Icon: '💃🏻 ',
        title: 'Dance',
    },
    {
        id: 7,
        Icon: '',
        title: 'tag 3',
    },

    {
        id: 8,
        Icon: '',
        title: 'tag 4',
    },

    {
        id: 9,
        Icon: '',
        title: 'tag 5',
    },

    {
        id: 10,
        Icon: '',
        title: 'tag 6',
    },

    {
        id: 11,
        Icon: '',
        title: 'tag 7',
    },
];

export const benefitsList = [
    {
        title: 'One Central Hub for the Armenian Community',
        description: 'No more searching across different platforms. Armat is your go-to directory for Armenian businesses, events, and nonprofits.',
    },
    {
        title: 'Boost Visibility for Your Business or Event',
        description: 'Whether you run a business, host events, or manage a nonprofit, Armat helps you reach the right audience and grow your impact.',
    },
    {
        title: 'Stay Informed and Get Involved',
        description: 'Subscribe to event updates, connect with local businesses, and find ways to engage with Armenian causes—all in just a few clicks.',
    },
    {
        title: 'Built by the Community, for Community',
        description: 'We understand the importance of community and cultural preservation. Armat was created to strengthen Armenian connections and empower our people worldwide.',
    },
];

