import { useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { useSelector } from 'react-redux';
import { EventGridCard } from 'fragments';
import { eventsYouLikeResponse } from './constants';
import {Svg} from 'assets';

export const EventsYouLike = () => {
    const { eventById, events } = useSelector((state) => ({
        eventById: state.events.eventById,
        events: state.events.events
    }));
    const carouselRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const evenList = events?.events?.filter((event) => event?.id !== eventById?.id);
    const handleOnChange = (previousSlide, { currentSlide, slidesToShow, totalItems }) => {
        setIsAtStart(currentSlide === 0);
        setIsAtEnd(currentSlide + slidesToShow >= totalItems);
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    return (
        <>
            {!!evenList?.length && (
                <div className='events-you-like'>
                    <div className='events-you-like-box'>
                        <div className='events-you-like-box-carousel-wrapper'>
                            <button
                                className={`slide-arrow-button slide-arrow-button_left ${isAtStart ? 'disabled-slide-arrow-button' : ''}`}
                                onClick={handlePrev}
                                disabled={isAtStart}
                            >
                                <Svg.ArrowLeftSlide />
                            </button>

                            <Carousel
                                ref={carouselRef}
                                responsive={eventsYouLikeResponse}
                                arrows={false}
                                afterChange={handleOnChange}
                            >
                                {evenList?.map((item, j) => (
                                    <div key={j}>
                                        <EventGridCard
                                            slide={true}
                                            boxShadow='none'
                                            event={item}
                                            detailBtn={true}
                                            containerClass="carousel-container"
                                            itemClass="carousel-item"
                                        />
                                    </div>
                                ))}
                            </Carousel>

                            <button
                                className={`slide-arrow-button slide-arrow-button_right ${isAtEnd ? 'disabled-slide-arrow-button' : ''}`}
                                onClick={handleNext}
                                disabled={isAtEnd}
                            >
                                <Svg.ArrowRightSlide />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
