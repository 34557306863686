import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Breadcrumb } from 'components';
import { EventCta, RsvpActions } from 'fragments';
import { BuyTicket } from './buyTicket';
import { dateConverter, originalDateConverter, useModal } from 'utils';
import { handleGetTicketInfo, renderDiff } from './constants';
import { Svg, Images } from 'assets';

export const EventInformation = () => {
    const { eventTickets, event, org } = useSelector((state) => ({
        eventTickets: state.tickets.eventTickets,
        event: state.events.eventById,
        org: state.orgs.organizationsById,
    }));
    const { left } = handleGetTicketInfo(eventTickets);
    const name = event?.org ? event.org.name : event?.creator ? event?.creator?.fullName : event?.creator === null && 'Deleted User';
    const converted = dateConverter(event);
    const originalConverter = originalDateConverter(event);
    const eventImage = event?.images?.length ? event?.images[event?.eventImage ? event?.eventImage : 0]?.url : Images.EventImage;
    const [dateChecker, setDateChecker] = useState(renderDiff(event));
    const { openModal } = useModal();

    useEffect(() => {
        const interval = setInterval(() => {
            setDateChecker(renderDiff(event));
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, [event]);

    return (
        <div className="event-information-wrapper">
            <div className="event-information-container">
                <Breadcrumb
                    className="event-information-breadcrumb-wrapper"
                    list={[
                        { to: '/', title: 'Home' },
                        { to: `/events`, title: 'Events' },
                        { title: 'Event Detail' }
                    ]}
                />

                <div className="single-event-image" style={{ backgroundImage: `url(${eventImage})` }}>
                    <div className="single-event-image-layar" />

                    <div className="event-date-time">
                        <p className="event-date">{moment.utc(event?.startDate).format('dddd, MMMM DD')}</p>
                        {!!eventTickets?.tickets?.length && (
                            <div className="only-left">
                                <p>{`Only ${left} Spots Left!`}</p>
                            </div>
                        )}

                        {!dateChecker?.isBeforeToday && (
                            <div className="time-left">
                                <div className="date-box">
                                    <p>{dateChecker?.days}</p>
                                    <span>Days</span>
                                </div>
                                <div className="date-box">
                                    <p>{dateChecker?.hours}</p>
                                    <span>Hours</span>
                                </div>
                                <div className="date-box">
                                    <p>{dateChecker?.minutes}</p>
                                    <span>Min</span>
                                </div>
                                <div className="date-box">
                                    <p>{dateChecker?.seconds}</p>
                                    <span>Seconds</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="space-between">
                        <div />
                        {!!event?.images?.length > 1 && (
                            <button className="event-images">
                                <p className="event-images-text">View All Photos</p>
                                <p className="event-images-text-mobile">View All</p>
                                <Svg.ArrowUpRight />
                            </button>
                        )}
                    </div>
                </div>

                <div className="single-event-information-box">
                    <p className="event-name">{event?.title}</p>
                    <div className="event-location-time" style={{ alignItems: 'flex-start' }}>
                        <div className="event-info-icon">
                            <Svg.EventCalendar />
                        </div>

                        {event?.timezoneOffset ? (
                            <div style={{ marginTop: '2px' }}>
                                <p>
                                    {event?.startTime
                                        ? `${originalConverter} ${event.timezoneOffset ? `(GMT ${event.timezoneOffset} )` : ''}`
                                        : `${moment(event.startDate).format('MM/DD/YYYY')}`}
                                </p>

                                {event?.startTime && (
                                    <p style={{ fontWeight: 'bold', marginTop: '4px' }}>
                                        {event.startTime ? `${converted} (Your Time)` : `${moment(event.startDate).format('MM/DD/YYYY')}`}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <p style={{ marginTop: '2px' }}>{`${moment(event?.startTime).format('llll')} ${
                                event?.timezoneOffset ? `(GMT ${event.timezoneOffset} )` : ''
                            }`}</p>
                        )}
                    </div>

                    {event?.address?.formattedAddress && (
                        <div className="event-location-time">
                            <div className="event-info-icon">
                                <Svg.EventLocation />
                            </div>
                            <p>{event?.address?.formattedAddress}</p>
                        </div>
                    )}

                    <div className="event-organizer-box">
                        <div className="flex">
                            <div className="organization-logo">
                                {event?.creator?.avatar ? (
                                    <img src={event?.creator?.avatar?.url} alt="icon" />
                                ) : (
                                    <Svg.NoOrganizationIcon2Svg />
                                )}
                            </div>
                            <p className="organizer-name">
                                <span>Organized by </span>
                                {event?.org ? (
                                    <a href={`/organization/${event?.org?.id}`} target={'_blank'} rel="noreferrer">
                                        {name}
                                    </a>
                                ) : (
                                    <span>{name}</span>
                                )}
                            </p>
                        </div>
                        <a className="contact-organizer" href={`mailto:${org?.email}`}>
                            <Svg.Messages />

                            <p className="contact-organizer-text">Contact Organizer</p>
                            <p className="contact-organizer-text-mobile">Contact</p>
                        </a>
                    </div>
                </div>
                <div className="action-buttons-box">
                    <RsvpActions event={event} />

                    {!!eventTickets?.tickets?.length && !dateChecker?.isBeforeToday && (
                        <button className="action-btn" onClick={() => openModal(<BuyTicket eventId={event?.eventId} />, 'noPaddings')}>
                            <Svg.BuyTicket />
                            <p> Buy Tickets</p>
                        </button>
                    )}

                    {event?.cta && !!Object.keys(event?.cta).length > 0 && <EventCta event={event} />}
                </div>
            </div>
        </div>
    );
};
