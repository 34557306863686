import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Checkbox, FormControlLabel } from '@mui/material';
import { organizationActions } from 'store';
import { ButtonMain, CancelBtn } from 'components';
import { Svg } from 'assets';
import { EDIT_ORG_TYPE } from './constants';

export const HoursBox = () => {
    const orgById = useSelector((state) => state.orgs.organizationsById);
    const dispatch = useDispatch();
    const [stateBool, setStateBool] = useState(false);
    const [times, setTime] = useState(
        orgById?.hours && Object.keys(orgById?.hours).length !== 0
            ? orgById?.hours
            : {
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: [],
            },
    );

    const shortDayNames = (name) => {
        switch (name) {
            case 'monday':
                return 'Mon';
            case 'tuesday':
                return 'tue';
            case 'wednesday':
                return 'wed';
            case 'thursday':
                return 'thu';
            case 'friday':
                return 'fri';
            case 'saturday':
                return 'sat';
            case 'sunday':
                return 'sun';
        }
    };

    const addNewRow = (key) => {
        let newObj = { ...times };
        newObj[key].push({
            from: moment().format('HH:00'),
            to: moment().format('HH:00'),
            available: true,
        });
        setTime(newObj);
        setStateBool(true);
    };

    const changeData = (e, index, key) => {
        let obj = { ...times };
        obj[key][index][e.target.name] = e.target.value;
        if (e.target.value === '') {
            obj[key][index][e.target.name] = !e.target.checked;
        }
        setTime(obj);
        setStateBool(true);
    };

    const removeRow = (key, index) => {
        let obj = { ...times };
        obj[key].splice(index, 1);
        setTime(obj);
        setStateBool(true);
    };

    useEffect(() => {
        if (stateBool === true && orgById?.id) {
            setTime(times);
        }
    }, [stateBool]);

    const handleSubmit = () => {
        const formData = {
            phoneNumber: orgById?.phoneNumber,
            email: orgById?.email,
            type: orgById?.type,
            name: orgById?.name,
            address: orgById?.address?.formattedAddress,
        };
        formData.hours = times;
        dispatch(organizationActions.editOrg(orgById?.id, formData, true));
    };

    return (
        <div className={`hourse-wrapper`}>
            <div className='subtitle-box'>
                <h3 className='subtitle'>Hours of Operation</h3>
            </div>
            <div className={`hourse-box`}>
                {Object.keys(times).map(function(key, weekDayIndex) {
                    return (
                        <div key={weekDayIndex}
                             className={`hours-card ${times[key].length ? 'hours-card-with_times' : ''}`}>
                            <p className='week-day' style={{ color: times[key].length ? '#BB3240' : '' }}>
                                {shortDayNames(key)}
                            </p>

                            <div style={{ width: '100%' }}>
                                {!times[key].length && (
                                    <button onClick={() => addNewRow(key)} className='add-hours'>
                                        <Svg.PlusCircleSvg />
                                        <span>Add Hours</span>
                                    </button>
                                )}

                                <div style={{ width: '100%' }}>
                                    {times[key].length
                                        ? times[key].map((item, index) => {
                                            return (
                                                <div key={index} className='time-box'>
                                                    <div className='hors-checkbox-block'>

                                                        <div className='hourse-form'>
                                                            <input
                                                                // style={`time-input   `}
                                                                // disabled={!item.isActive}
                                                                name='from'
                                                                value={item.from}
                                                                type='time'
                                                                onChange={(e) => {
                                                                    changeData(e, index, key);
                                                                }}
                                                            />
                                                            <p className='small-line'>-</p>
                                                            <input
                                                                // style={`time-input`}
                                                                // disabled={!item.isActive}
                                                                //   disabled={stateBool}
                                                                name='to'
                                                                value={item.to}
                                                                type='time'
                                                                onChange={(e) => {
                                                                    changeData(e, index, key);
                                                                }}
                                                            />

                                                            {/*<ValidationInput*/}
                                                            {/*    style={`time-input   `}*/}
                                                            {/*    errorFalse={true}*/}
                                                            {/*    // disabled={!item.isActive}*/}
                                                            {/*    name="from"*/}
                                                            {/*    value={item.from}*/}
                                                            {/*    type="time"*/}
                                                            {/*    onChange={(e) => {*/}
                                                            {/*        changeData(e, index, key);*/}
                                                            {/*    }}*/}
                                                            {/*/>*/}

                                                            {/*<p className={`small-line`}>-</p>*/}

                                                            {/*<ValidationInput*/}
                                                            {/*    style={`time-input`}*/}
                                                            {/*    errorFalse={true}*/}
                                                            {/*    // disabled={!item.isActive}*/}
                                                            {/*    //   disabled={stateBool}*/}
                                                            {/*    name="to"*/}
                                                            {/*    value={item.to}*/}
                                                            {/*    type="time"*/}
                                                            {/*    onChange={(e) => {*/}
                                                            {/*        changeData(e, index, key);*/}
                                                            {/*    }}*/}
                                                            {/*/>*/}
                                                        </div>


                                                        {/*<div className='check-box'>*/}
                                                        {/*    <FormControlLabel*/}
                                                        {/*        name='available'*/}
                                                        {/*        onChange={(e) => {*/}
                                                        {/*            changeData(e, index, key);*/}
                                                        {/*        }}*/}
                                                        {/*        control={*/}
                                                        {/*            <Checkbox*/}
                                                        {/*                sx={{*/}
                                                        {/*                    color: '#D5D7DA',*/}
                                                        {/*                    '&.Mui-checked': {*/}
                                                        {/*                        color: '#BB3240',*/}
                                                        {/*                    },*/}
                                                        {/*                }} />}*/}
                                                        {/*        label={<span className={`available-text`}>Closed</span>}*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div className="fourse-submit">
                                                        <div className="check-box">
                                                            <FormControlLabel
                                                                name="available"
                                                                onChange={(e) => {
                                                                    changeData(e, index, key);
                                                                }}
                                                                control={<Checkbox />}
                                                                label={<span
                                                                    className={`available-text`}>Not Available</span>}
                                                            />
                                                        </div>
                                                        <button
                                                            className={`remove-btn`}
                                                            onClick={() => {
                                                                removeRow(key, index);
                                                            }}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : ''}
                                    {times[key].length ? (
                                        <button onClick={() => addNewRow(key)} className='more-hours'>
                                            Add more hours
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className='edit-org-btns'>
                <CancelBtn />
                <ButtonMain onClick={handleSubmit} actionTye={EDIT_ORG_TYPE} text='Save' />
            </div>

            {/*<div className="info-feature">*/}
            {/*    <div className="info-brn">*/}
            {/*        <CreateCancel title="Save" handleSubmit={handleSubmit} actionType={editActionType} />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};
