import React from 'react';
import { Loader } from 'components';
import { FindLoad } from '../../utils';

export const ButtonMain = (
    {
        text,
        disabled,
        actionTye,
        contained = true,
        type = 'button',
        onClick,
        load,
        textColor,
        className,
        loadStyles,
    }) => {
    const loading = FindLoad(actionTye);

    return (
        <button type={type} disabled={disabled || loading} onClick={onClick}
                className={`button-main ${className ? className : ''}`}>
            {loading || load ? (
                <Loader styles={{ color: contained ? '#FFF' : '#347AF0', ...loadStyles }} />
            ) : (
                <div className='flex-all-center'>
                    {/*{startAdornment && <div className="button-start-adornment">{startAdornment}</div>}*/}
                    <div style={{ color: textColor ? textColor : '' }}>{text}</div>
                    {/*{endAdornment && <div className="button-end-adornment">{endAdornment}</div>}*/}
                </div>
            )}
        </button>
    );
};
