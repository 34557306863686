import { DateRow, PriceRow, TextRow, NotYetScreen } from 'components';
import { Images, Svg } from 'assets';
import { useSelector } from 'react-redux';
import React from 'react';

export const InvoicesMobile = ({ setShow }) => {
    const { invoices } = useSelector((state) => ({
        invoices: state?.payments?.invoices,
    }));

    return (
        <div className="invoices-mobile-box">
            <p className="payment-method-title">Invoices</p>
            <div className="invoice-cards-wrapper">
                {invoices?.length ? (
                    invoices?.map((i, j) => (
                        <div className="invoice-mobile-card" key={j}>
                            <div className="invoice-info-box">
                                <p className="invoice-box-title">Date:</p>
                                <div className="invoice-box-value">
                                    <DateRow date={i?.createdAt ? i?.createdAt : ''} format={'ll'} />
                                </div>
                            </div>
                            <div className="invoice-info-box">
                                <p className="invoice-box-title">Desc:</p>
                                <div className="invoice-box-value">
                                    <TextRow name={i?.description} />
                                </div>
                            </div>
                            <div className="invoice-info-box invoice-info-box-padding-border">
                                <p className="invoice-box-title">Amt:</p>
                                <div className="invoice-box-value">
                                    <PriceRow info={i?.amountPaid ? i?.amountPaid : 0} />
                                </div>
                            </div>

                            <div className="invoice-mobile-actions flex-end">
                                <button className="invoice-mobile-actions-button" onClick={() => setShow(i)}>
                                    <Svg.EyeSvg />
                                </button>
                                {/*<button className="invoice-mobile-actions-button">*/}
                                {/*    <Svg.Download />*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    ))
                ) : (
                    <NotYetScreen
                        image={Images.EmptyInvoices}
                        title={'No Invoices Available'}
                        desc={"It looks like you don't have any invoices yet. Once you make a purchase, they will appear here."}
                    />
                )}
            </div>
        </div>
    );
};
