import React from 'react';
import { Svg } from 'assets';

export const linksList = [
    { title: 'Businesses', link: '/businesses' },
    { title: 'Events', link: '/events' },
    { title: 'Nonprofits', link: '/nonprofits' },
    { title: 'Contact Us', link: '/contact-us' },
    { title: 'Privacy', link: '/privacy' },
];

export const socialLinks = [
    { Icon: <Svg.TwitterFSvg />, link: 'https://x.com/armatplatform' },
    { Icon: <Svg.LinkedInFSvg />, link: 'https://www.linkedin.com/company/armat-platform/' },
    { Icon: <Svg.FacebookFSvg />, link: 'https://www.facebook.com/armatapp/' },
    { Icon: <Svg.InstagramFSvg />, link: 'https://www.instagram.com/armatplatform/' },
];
