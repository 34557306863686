import { call, put, takeLatest } from 'redux-saga/effects';
import { homeService } from './home.service';
import { GET_EVENTS_BUSINESS_NONPROFIT_HOME, GET_EVENTS_BUSINESS_NONPROFIT_SUCCESS_HOME } from './home.types';

import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { ORG_TYPES } from '../../fragments';
import { Images } from '../../assets';

function* getEventBusinessNonProfit({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
            const event = yield call(homeService.getEventsService, payload?.params);
            const currentEvent = event?.data?.events?.find((item) => item?.images?.length > 0)

            const business = yield call(homeService.getOrgService, { ...payload?.params, type: ORG_TYPES.BUSINESS });
            const currentBusiness  = business?.data?.orgs?.find((item) => item?.images?.length > 0 )

            const nonProfit = yield call(homeService.getOrgService, { ...payload?.params, type: ORG_TYPES.NON_PROFIT });
            const currentNonProfit  = nonProfit?.data?.orgs?.find((item) => item?.images?.length > 0  )

            yield put({
                type: GET_EVENTS_BUSINESS_NONPROFIT_SUCCESS_HOME,
                payload: [
                    {
                        type: 'event',
                        title: 'Discover Events',
                        data: currentEvent,
                        image: currentEvent ? currentEvent?.images[currentEvent?.eventImage || 0]?.url : Images.EventImage,
                    },
                    {
                        type: 'business',
                        title: 'Find Businesses',
                        data: currentBusiness,
                        image: currentBusiness ? currentBusiness?.images[currentBusiness?.mainImage || 0]?.url : Images.BusinessImage,
                    },
                    {
                        type: 'nonProfit',
                        title: 'Support Nonprofits',
                        data: currentNonProfit,
                        image: currentNonProfit ? currentNonProfit?.images[currentNonProfit?.mainImage || 0]?.url : Images.OrgImage,
                    },
                ],
            });
        // catch (e) {
        //
        // }
        //
        // try {
        //     const business = yield call(homeService.getOrgService, { ...payload?.params, type: ORG_TYPES.BUSINESS });
        //     const list  = business?.data?.orgs?.find((item) => item?.images?.length > 0)
        //     yield put({
        //         type: GET_EVENTS_BUSINESS_NONPROFIT_SUCCESS_HOME,
        //         payload: [{ business: list ? list?.images[list?.mainImage] : Images.BusinessImage }],
        //     });
        // } catch (e) {
        //
        // }
        //
        //
        // try {
        //     const nonProfit = yield call(homeService.getOrgService, { ...payload?.params, type: ORG_TYPES.NON_PROFIT });
        //     yield put({
        //         type: GET_EVENTS_BUSINESS_NONPROFIT_SUCCESS_HOME,
        //         payload: [{ nonProfit: nonProfit?.data?.orgs }],
        //     });
        // } catch (e) {
        //
        // }

        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchHome = function* () {
    yield takeLatest(GET_EVENTS_BUSINESS_NONPROFIT_HOME, getEventBusinessNonProfit);
};
