import React  from 'react';
import { Discover, AboutBusiness, UpcomingEvents, SupportOrgs, BusinessNearYou, Benefits } from './fragments';

export const Home = () => {

    return (
        <div className="home-page">
            <Discover />
            <AboutBusiness />
            <UpcomingEvents />
            <SupportOrgs />
            <BusinessNearYou />
            <Benefits />
        </div>
    );
};
