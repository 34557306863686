import React from 'react';
import { TitleAndEditBtn } from './core';
import { Svg } from 'assets';
import { PhoneRow } from 'components';
import { useSelector } from 'react-redux';
import SingleMap from '../../../fragments/map/singleMap';
import { canManageOrg, shortDayNames } from './constants';
import { startEndTime, useModal, userInfo } from 'utils';
import { ReviewsModal } from './reviewsModal';
import { EditOrg } from '../../../fragments';

export const OrgDetailsSection = () => {
    const { org, orgSocials, orgAdmins } = useSelector((state) => ({
        org: state.orgs.organizationsById,
        orgSocials: state.orgs.orgSocials,
        orgAdmins: state.orgs.orgAdmins,
    }));
    const { openModal } = useModal();
    const creatorInfo = canManageOrg(userInfo, org, orgAdmins);
    const linksReviews =
        creatorInfo === true
            ? true
            : orgSocials?.yelpLink ||
              orgSocials?.facebookLink ||
              orgSocials?.youtubeLink ||
              orgSocials?.instagramLink ||
              orgSocials?.googleLink ||
              orgSocials?.twitterLink;

    const socialReviews = creatorInfo === true ? true : orgSocials?.googleReviews?.rating || orgSocials?.yelpReviews?.rating;
    const filteredByKey = org?.hours && Object.fromEntries(Object.entries(org?.hours).filter(([value]) => value?.length));
    const checkHours = creatorInfo ? true : filteredByKey && Object.keys(filteredByKey).length;
    const checkInfoSection = org?.address || org?.email || org?.phoneNumber || checkHours || linksReviews;
    const openEditModal = (index) => {
        openModal(<EditOrg index={index} />);
    };

    return (
        <div className="org-details-section" id={'hours'}>
            <div className="org-box-wrapper">
                {(org?.description || creatorInfo) && (
                    <div className="org-white-box">
                        <TitleAndEditBtn title={'Details'} showBtn={creatorInfo} handleClick={() => openModal(<EditOrg index={0} />)} />
                        <div className="org-details-wrapper">
                            <p
                                className="org-description"
                                dangerouslySetInnerHTML={{ __html: org?.description ? org?.description : 'Not Set' }}
                            />
                            {/*<div className="org-created-date">*/}
                            {/*    <Svg.OrgCalendar />*/}
                            {/*    <p>Created: 12/06/2024</p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )}

                {!!checkInfoSection && (
                    <div className="org-white-box">
                        <TitleAndEditBtn title={'Contact Info'} showBtn={creatorInfo} handleClick={() => openEditModal(0)} />
                        <div className="org-info-details-wrapper">
                            {org?.address && (
                                <div className="org-info-details-box">
                                    <div className="svg-box">
                                        <Svg.EventLocation />
                                    </div>
                                    <p>Address:</p>

                                    <a
                                        className="address-link"
                                        target={'_blank'}
                                        href={`https://www.google.com/maps?saddr=My+Location&daddr=${org?.address?.lat},${org?.address?.lng}`}
                                        rel="noreferrer">
                                        {org?.address?.formattedAddress}
                                    </a>
                                </div>
                            )}
                            {org?.email && (
                                <div className="org-info-details-box">
                                    <div className="svg-box">
                                        <Svg.Sms />
                                    </div>
                                    <p>Email:</p>
                                    <a href={`mailto:${org?.email}`}>{org?.email}</a>
                                </div>
                            )}
                            {org?.phoneNumber && (
                                <div className="org-info-details-box">
                                    <div className="svg-box">
                                        <Svg.CallCalling />
                                    </div>
                                    <p>Phone:</p>
                                    <a href={`tel:+${org?.phoneNumber}`}>
                                        <PhoneRow phone={org?.phoneNumber} />
                                    </a>
                                </div>
                            )}
                        </div>

                        {checkHours && (
                            <div className="org-info-details-wrapper" style={{ paddingBottom: '16px' }}>
                                <TitleAndEditBtn title={'Hours of Operation'} showBtn={creatorInfo} handleClick={() => openEditModal(2)} />
                                {org?.hours && !!Object.keys(org?.hours)?.length && (
                                    <div className="org-days-hours">
                                        {org?.hours && Array.isArray(org?.hours) === false
                                            ? Object.keys(org?.hours).map((i, j) => {
                                                  return (
                                                      !!org?.hours[i]?.length && (
                                                          <div key={j} className="org-days-hours-box">
                                                              <p className="org-work-week">{shortDayNames(i)}</p>
                                                              <div>
                                                                  {org?.hours[i]?.map((k, m) =>
                                                                      k?.available ? (
                                                                          <p key={m} className="org-work-hours">
                                                                              {`${startEndTime(k?.from)} - ${startEndTime(k?.to)}`}
                                                                          </p>
                                                                      ) : (
                                                                          <p key={m} className="org-work-closed">
                                                                              Closed
                                                                          </p>
                                                                      )
                                                                  )}
                                                              </div>
                                                          </div>
                                                      )
                                                  );
                                              })
                                            : ''}
                                    </div>
                                )}
                            </div>
                        )}

                        {linksReviews && (
                            <>
                                <TitleAndEditBtn title={'Connect with Us'} showBtn={creatorInfo} handleClick={() => openEditModal(4)} />
                                <div className="org-socials">
                                    {orgSocials?.yelpLink && (
                                        <a href={orgSocials?.yelpLink} target="_blank" rel="noreferrer">
                                            <Svg.SocialYelp />
                                        </a>
                                    )}
                                    {orgSocials?.facebookLink && (
                                        <a href={orgSocials?.facebookLink} target="_blank" rel="noreferrer">
                                            <Svg.FacebookFSvg />
                                        </a>
                                    )}
                                    {orgSocials?.youtubeLink && (
                                        <a href={orgSocials?.youtubeLink} target="_blank" rel="noreferrer">
                                            <Svg.SocialYoutube />
                                        </a>
                                    )}
                                    {orgSocials?.instagramLink && (
                                        <a href={orgSocials?.instagramLink} target="_blank" rel="noreferrer">
                                            <Svg.Instagram />
                                        </a>
                                    )}
                                    {orgSocials?.twitterLink && (
                                        <a href={orgSocials?.twitterLink} target="_blank" rel="noreferrer">
                                            <Svg.Twitter />
                                        </a>
                                    )}
                                    {orgSocials?.googleLink && (
                                        <a href={orgSocials?.googleLink} target="_blank" rel="noreferrer">
                                            <Svg.SocialGoogle />
                                        </a>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            <div className="org-box-wrapper">
                {socialReviews && (
                    <div className="org-white-box">
                        <TitleAndEditBtn title={'Reviews'} showBtn={creatorInfo} handleClick={() => openEditModal(3)} />
                        <div className="org-review-wrapper">
                            {orgSocials?.googleReviews && (
                                <div className="review-box">
                                    <p className="review-title">Google</p>
                                    <div className="review-info-box">
                                        <Svg.Star />
                                        <p>{orgSocials?.googleReviews?.rating}</p>
                                        <button onClick={() => openModal(<ReviewsModal type="Google" info={orgSocials?.googleReviews} />)}>
                                            {`(${orgSocials?.googleReviews?.rating ? orgSocials?.googleReviews?.numReviews : 0} reviews)`}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {orgSocials?.yelpReviews && (
                                <div className="review-box">
                                    <p className="review-title">Yelp</p>
                                    <div className="review-info-box">
                                        <Svg.Star />
                                        <p>{orgSocials?.yelpReviews?.rating}</p>
                                        <button onClick={() => openModal(<ReviewsModal type="Yelp" info={orgSocials?.yelpReviews} />)}>
                                            {`(${orgSocials?.yelpReviews?.rating ? orgSocials?.yelpReviews?.numReviews : 0} reviews)`}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {org?.address && (
                    <div className="org-white-box" style={{ padding: 0 }}>
                        <div className="org-details-wrapper" style={{margin: 0}}>
                            <SingleMap address={org?.address} />
                        </div>
                        <div className="org-white-box" style={{ paddingTop: 16, paddingBottom: 16 }}>
                            <TitleAndEditBtn
                                title={org?.address?.city ? org?.address?.city : ''}
                                handleClick={() => openEditModal(0)}
                                showBtn={creatorInfo}
                            />

                            {org?.address?.formattedAddress && <p className="map-title">{org?.address?.formattedAddress}</p>}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
