import { GET_EVENTS_BUSINESS_NONPROFIT_SUCCESS_HOME } from './home.types';

const initialState = {
    eventBusinessNonProfit: []
};

export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_BUSINESS_NONPROFIT_SUCCESS_HOME:
            return {
                ...state,
                eventBusinessNonProfit: action.payload,
            };

        default:
            return state;
    }
};
