import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './auth.service';
import {
    SIGN_UP,
    LOG_IN,
    LOG_OUT,
    GET_RECOVERY_LINK,
    RESET_PASSWORD,
    CHANGE_PASSWORD_REQUEST,
    GET_MY_PROFILE,
    GET_MY_PROFILE_SUCCESS,
    DELETE_ACCOUNT_REQUEST,
    LOG_IN_SUCCESS,
    EDIT_MY_ACCOUNT,
    INVITE_USER,
    SEARCH,
    SEARCH_SUCCESS, GET_MY_ORGS, GET_MY_ORGS_SUCCESS,
} from './auth.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

function* signUp({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.signUp, payload.body);
        localStorage.setItem('access-token', res.data.token);

        const info = yield call(authService.myProfileService);
        localStorage.setItem('userInfo', JSON.stringify(info.data));
        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: info.data,
        });
        yield put({
            type: LOG_IN_SUCCESS,
            payload: res.data.token,
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* logIn({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.signIn, payload);
        localStorage.setItem('access-token', res.data.token);

        const info = yield call(authService.myProfileService);
        localStorage.setItem('userInfo', JSON.stringify(info.data));

        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: info.data,
        });
        window.location.reload();

        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* logOut({ type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.logOut);
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        window.location.replace('/');
        localStorage.clear();
        sessionStorage.clear();
    }
}

function* getLink({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.getLink, payload.email);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* resetPassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.resetPass, action?.payload?.passwords);
        localStorage.setItem('access-token', res?.data?.token);

        const info = yield call(authService.myProfileService);
        localStorage.setItem('userInfo', JSON.stringify(info.data));

        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: info.data,
        });

        yield put({
            type: LOG_IN_SUCCESS,
            payload: res.data.token,
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* changePassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.changePasswordService, action.payload.data);
        localStorage.setItem('access-token', res?.data?.token);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* getMyProfile(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));

    try {
        const info = yield call(authService.myProfileService);
        localStorage.setItem('userInfo', JSON.stringify(info.data));
        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: info.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* editAccount({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editAccountService, payload?.data);
        yield put({
            type: GET_MY_PROFILE,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deleteAccount({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.deleteAccountService, payload?.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace('/');
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* inviteUser({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.inviteUserService, payload);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
function* search({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.searchService, payload);
        yield put({
            type: SEARCH_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getMyOrgs({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getMyOrgsService);
        yield put({
            type: GET_MY_ORGS_SUCCESS,
            payload: res?.data,
        });

        const localId = localStorage.getItem('orgId');
        if (res?.data) {
            if (!localId) {
                localStorage.setItem('orgId', res?.data?.[0]?.id);
            }
        }
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchAuth = function* watchUserAuth() {
    yield takeLatest(SIGN_UP, signUp);
    yield takeLatest(LOG_IN, logIn);
    yield takeLatest(LOG_OUT, logOut);
    yield takeLatest(GET_MY_PROFILE, getMyProfile);
    yield takeLatest(GET_RECOVERY_LINK, getLink);
    yield takeLatest(RESET_PASSWORD, resetPassword);
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
    yield takeLatest(EDIT_MY_ACCOUNT, editAccount);
    yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount);
    yield takeLatest(INVITE_USER, inviteUser);
    yield takeLatest(SEARCH, search);
    yield takeLatest(GET_MY_ORGS, getMyOrgs);
};
