import axios from 'axios';

export const authService = {
    signUp: (body) => axios.post('/users', body),

    signIn: (body) => axios.post('/auth/signin', body),

    logOut: () => axios.get(`/auth/logout`, { auth: true }),

    getLink: (email) => axios.get(`/auth/forgotPassword/${email}`),

    resetPass: (info) => axios.post('/auth/resetPassword', { ...info }, { headers: { 'reset-token': info.token } }),

    changePasswordService: (data) => axios.post('/auth/changePassword', data, { auth: true }),

    myProfileService: () => axios.get(`/users/myProfile`, { auth: true }),

    editAccountService: (data) => axios.patch(`/users`, data, { auth: true }),

    deleteAccountService: (id) => axios.delete(`/users/${id}`, { auth: true }),

    getAddressService: (address) => axios.post(`/address`, { address: address }),

    inviteUserService: (params) => axios.patch(`/users/invite`, params, { auth: true }),

    searchService: (params) => axios.post(`/search`, null, { params: { ...params } }),

    subscribeNews: (email) => axios.post(`/newsletter/subscribe`, { email: email }),

    getMyOrgsService: (email) => axios.get(`/orgs/user`, { auth: true }),
};
