import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as moment from 'moment';
import { TextRow } from 'components';
import { RsvpActions } from './rsvpActions';
import { dateConverter, originalDateConverter } from 'utils';
import { Svg } from 'assets';

export const EventGridCard = ({ event, showRsvp, detailBtn, boxShadow,slide }) => {
    const navigate = useNavigate();
    const eventImage = event?.images?.length > 0 ? event.images[event.eventImage ? event.eventImage : 0].url : null;

    const handleNavigate = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/event/${event?.eventId}`);
    };

    return (
        <div
            style={{ cursor: detailBtn ? 'default' : 'pointer', boxShadow: boxShadow ? boxShadow : '', width: slide ? '100%' : '' }}
            className="event-grid-card-wrapper"
            onClick={!detailBtn ? (e) => handleNavigate(e) : null}>
            <div className="event-image-box">
                <div className="event-type-box">
                    <div className="event-type">{event?.locationType === 'VIRTUAL' ? 'ONLINE' : 'IN-PERSON'}</div>
                </div>
                <div className="event-image">{eventImage ? <img src={eventImage} alt="icon" /> : <Svg.Gallery />}</div>
            </div>

            <div className="event-information-box">
                <div className="event-title">
                    <TextRow name={event?.title} />
                </div>
                <div className="date-time-location-box">
                    <div className="event-info-box">
                        <div className="icon-box">
                            <Svg.EventCalendar />
                        </div>
                        {event?.timezoneOffset ? (
                            <div>
                                {event?.locationType === 'VIRTUAL' ? (
                                    <p>
                                        {event?.startTime
                                            ? `${originalDateConverter(event)} ${
                                                  event.timezoneOffset ? `(GMT ${event.timezoneOffset} )` : ''
                                              }`
                                            : `${moment(event.startDate).format('MM/DD/YYYY')}`}
                                    </p>
                                ) : (
                                    <p>
                                        {event.startTime
                                            ? `${dateConverter(event)} (Your Time)`
                                            : `${moment(event.startDate).format('MM/DD/YYYY')}`}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <p>{`${moment(event?.startTime).format('llll')} ${
                                event?.timezoneOffset ? `(GMT ${event?.timezoneOffset} )` : ''
                            }`}</p>
                        )}
                    </div>
                    {event?.address?.formattedAddress && (
                        <div className="event-info-box">
                            <div className="icon-box">
                                <Svg.EventLocation />
                            </div>
                            <TextRow name={event?.address?.formattedAddress} textWidth={8} />
                        </div>
                    )}
                </div>
                {detailBtn && (
                    <NavLink
                        style={{ boxShadow: 'none'}}
                        className="rsvp-btn-box" onClick={handleNavigate}>
                        View Detail <Svg.ArrowUpRight />
                    </NavLink>
                )}
                {showRsvp && <RsvpActions event={event} />}
            </div>
        </div>
    );
};
