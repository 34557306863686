import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Popover } from '@mui/material';
import { Svg } from 'assets';
import { RenderScroll, token, useModal, userInfo } from 'utils';
import { Links } from './constants';
import { Invite, LogIn, SignUp } from '../../auth';
import { authActions, notificationsActions } from 'store';
import { TextRow } from 'components';
import { NotificationBox } from './notificationBox';

export const LinksBoxDesktop = ({ showSearch, toggleSearch, openingMenu, toggleMenu }) => {
    const { openModal } = useModal();
    const { myProfile, myOrgs } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
        myOrgs: state.auth.myOrgs,
    }));
    const dispatch = useDispatch();
    const location = useLocation();
    const user = myProfile || userInfo;
    const navigate = useNavigate();
    const scrollPos = RenderScroll();

    useEffect(() => {
        if (token) {
            dispatch(notificationsActions.getNotifications({ pageSize: 6, page: 1, userId: userInfo && userInfo.id }));
        }
    }, [token]);

    const handleNavigate = (info) => {
        toggleMenu(null);
        if (info?.clickFn) {
            if (info?.clickFn === 'invite') {
                openModal(<Invite />);
            }
        } else {
            navigate(info?.link);
        }
    };

    const handleLogout = () => {
        dispatch(authActions.logOut());
    };

    return (
        <div className='header-buttons-box'>
            <div className='layout-searched-box'>
                {scrollPos > 100 && (
                    <button onClick={toggleSearch}
                            className={`show-search-btn ${showSearch ? 'show-search-btn-show' : ''}`}>
                        <Svg.Search />
                    </button>
                )}
            </div>

            {user ? (
                <>
                    <div className='user-info-and-notifications'>

                        <button className={`loggedIn-header ${scrollPos > 100 ? 'loggedIn-header-layout' : ''}`}
                                onClick={toggleMenu}>
                            <div className='loggedIn-user-svg-block' style={myProfile?.avatar?.url && {padding:'0px'}}>
                                {myProfile?.avatar?.url ?
                                    <img src={myProfile?.avatar?.url} alt='avatar' />
                                    :
                                    <Svg.User />
                                }
                            </div>

                            <TextRow name={user?.fullName} textWidth={15}  />
                            <div style={{ marginTop: '4px' }}>
                                <Svg.DownBtn style={openingMenu ? { transform: 'rotate(180deg)' } : {}} />
                            </div>
                        </button>

                        <NotificationBox />
                    </div>

                    {openingMenu && (
                        <Popover
                            id={'popover-my-profile'}
                            anchorEl={openingMenu}
                            open={openingMenu}
                            onClose={toggleMenu}
                            transition
                            sx={
                                {
                                    // width: '230px',
                                }
                            }
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                            <div className='desktop-navigation-bar-box'>
                                <div className='desktop-navigation-bar-links-block'>
                                    {Links(myOrgs)?.map((i, j) => i && (
                                        <button
                                            className={`${i?.link === location?.pathname ? 'active-desktop-menu-link' : ''}`}
                                            key={j}
                                            onClick={() =>
                                                i?.name === 'Dashboard view' ? window.location.replace('/dashboard/') : handleNavigate(i)
                                            }>
                                            <div className='icon-box'>{i?.icon}</div>

                                            <p>{i?.name}</p>
                                        </button>
                                    ))}
                                </div>

                                <div className='desktop-logout-button-block'>
                                    <button className='desktop-logout-button' onClick={handleLogout}>
                                        <p>Log Out</p>

                                        <Svg.LogOut className='desktop-logout-button-svg' />
                                    </button>
                                </div>
                            </div>
                        </Popover>
                    )}
                </>
            ) : (
                <div className='flex header-login-btns'>
                    <button className='sign-in' onClick={() => openModal(<LogIn />)}>
                        Log in
                    </button>
                    <button className='sign-up' onClick={() => openModal(<SignUp />)}>
                        Sign up
                    </button>
                </div>
            )}
        </div>
    );
};
