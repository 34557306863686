import { combineReducers } from 'redux';
import { httpRequestsOnLoadReducer } from '../http_requests_on_load';
import { httpRequestsOnSuccessReducer } from '../http_requests_on_success';
import { httpRequestsOnErrorsReducer } from '../http_requests_on_errors';
import { authReducer } from '../auth';
import { ordersReducer } from '../orders';
import { eventsReducer } from '../events';
import { organizationsReducer } from '../organizations';
import { paymentsReducer } from '../payments';
import { ticketsReducer } from '../tickets';
import { notificationsReducer } from '../notifications';
import { homeReducer } from '../home';

export const appReducer = combineReducers({
    auth: authReducer,
    orders: ordersReducer,
    events: eventsReducer,
    orgs: organizationsReducer,
    payments: paymentsReducer,
    tickets: ticketsReducer,
    notifications: notificationsReducer,
    homeStore: homeReducer,

    httpOnLoad: httpRequestsOnLoadReducer,
    httpOnSuccess: httpRequestsOnSuccessReducer,
    httpOnError: httpRequestsOnErrorsReducer,
});
