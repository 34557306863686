import { GET_MY_ORGS_SUCCESS, GET_MY_PROFILE_SUCCESS, LOG_IN_SUCCESS, SEARCH_SUCCESS } from './auth.types';

const initialState = {
    accessToke: null,
    myProfile: null,
    searchResults: null,
    myOrgs: [],
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                myProfile: action.payload,
            };

        case LOG_IN_SUCCESS:
            return {
                ...state,
                accessToke: action.payload,
            };

        case SEARCH_SUCCESS:
            return {
                ...state,
                searchResults: action.payload,
            };

        case GET_MY_ORGS_SUCCESS:
            return {
                ...state,
                myOrgs: action.payload,
            };
        default:
            return state;
    }
};
