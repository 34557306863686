import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import moment from 'moment';
import { Svg } from 'assets';
import { MinLoader, TextRow } from 'components';
import { FindLoad, ProfileInfo, startEndTime, useModal, userInfo, useWindowDimensions } from 'utils';
import { organizationActions } from 'store';
import { LogIn } from 'fragments';
import { canManageOrgNoAdmin, CLAIM_LOAD, FOLLOW, UN_FOLLOW } from './constants';

export const OrgInformation = () => {
    const { hasClaim, org, myFollows, orgAdmins } = useSelector((state) => ({
        hasClaim: state.orgs.hasClaim,
        org: state.orgs.organizationsById,
        myFollows: state.orgs.myFollows,
        orgAdmins: state.orgs.orgAdmins,
    }));
    let weekDayName = moment.utc().format('dddd').toLowerCase();
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const loader = FindLoad(CLAIM_LOAD);
    const followLoader = FindLoad(FOLLOW);
    const unFollowLoader = FindLoad(UN_FOLLOW);
    const creatorInfoNoAdmin = canManageOrgNoAdmin(userInfo, org, orgAdmins);
    const aboutClaim = creatorInfoNoAdmin === true ? true : creatorInfoNoAdmin === true ? true : !!(org && org?.manager && org?.manager);
    const myFollowsInfo = myFollows?.find((i) => i?.userId === userInfo.id && i.orgId === org?.id);
    const { width } = useWindowDimensions();
    const { token } = ProfileInfo();

    let settings = {
        dots: false,
        infinite: org?.images?.length > 1,
        speed: 500,
        slidesToShow:
            org?.images?.length > 2
                ?  width > 1279 ? 1 : (width > 768 ? 1 : 1)
                : (org?.images?.length > 1 ? width > 1279 ? 2 : (width > 768 ? 1 : 1) : 1),
        slidesToScroll: 1,
    };
    const handleFollow = () => {
        if (userInfo) {
            const date = {
                org: org?.id,
            };
            if (myFollowsInfo?.id) {
                dispatch(organizationActions.unfollow(org?.id));
            } else {
                dispatch(organizationActions.follow(date));
            }
        } else {
            openModal(<LogIn />);
        }
    };

    const handleClaim = () => {
        if (token) {
            dispatch(organizationActions.claim(org?.id));
        } else {
            openModal(<LogIn />);
        }
    };

    return (
        <div>
            <div className='org-images-slider-box'>
                {!!org?.images?.length && (
                    <Slider {...settings}>
                        {org?.images?.map((i, j) => (
                            <img src={i?.url} alt={`logo-${j}`} key={i?._id || j} />
                        ))}
                    </Slider>
                )}
            </div>
            <div className='org-information-wrapper'>
                <div className='org-information'>
                    <div className='org-information-box'>
                        <div className='org-avatar-box'>
                            {org?.avatar ? <img src={org?.avatar?.url} alt='avatar' /> : <Svg.NoOrganizationIcon2Svg />}
                        </div>
                        <p className='org-name'>
                            <TextRow name={org?.name} />
                        </p>
                    </div>

                    <div className='org-info'>
                        <div className='org-categories-and-hours'>
                            {!!org?.categories?.length && (
                                <div className='org-categories-wrapper'>
                                    {org?.categories?.map((i, j) => (
                                        <div className='org-categories-box' key={j}>
                                            <p>{i?.text}</p>
                                            <div className='categories-circle' />
                                        </div>
                                    ))}
                                </div>
                            )}

                            {org?.hours && !!Object.keys(org?.hours)?.length && (
                                <div className='org-today-hour'>
                                    {org?.hours[weekDayName] &&
                                        org?.hours[weekDayName]?.map((i, j) =>
                                            i?.available ? (
                                                <p key={j} className='today-working-hour'>
                                                    {`${startEndTime(i?.from)} - ${startEndTime(i?.to)}`}
                                                </p>
                                            ) : (
                                                <p className='closed' key={j}>
                                                    Closed now.
                                                </p>
                                            ),
                                        )}
                                    <a className='see-hours' href={'#hours'}>
                                        See Hours
                                    </a>
                                </div>
                            )}

                            {org?.website && (
                                <a className='website-link' href={org?.website} target='_blank' rel='noreferrer'>
                                    Visit the website
                                    <Svg.Export />
                                </a>
                            )}
                        </div>

                        <div className='follow-claim-btns'>
                            <button
                                className={`${myFollowsInfo?.id ? 'following-claim-wrapper' : 'follow-claim-wrapper'}`}
                                onClick={handleFollow}
                            >
                                {followLoader || unFollowLoader ?
                                    <MinLoader margin='0' width={'20px'} height={'20px'} /> : myFollowsInfo?.id ? <Svg.ProfileUserCheck /> :
                                        <Svg.UserPlus />}
                                <p>{myFollowsInfo?.id ? 'Following' : 'Follow'}</p>
                            </button>

                            {aboutClaim ? (
                                <button onClick={handleClaim}>
                                    <Svg.ClaimedIcon />
                                    <p>Claimed</p>
                                </button>
                            ) : hasClaim ? (
                                <div className='claim-is-pending'>
                                    <Svg.ClaimedIcon />
                                    <p>Your Claim is Pending</p>
                                </div>
                            ) : (
                                <button onClick={handleClaim}>
                                    <>
                                        {loader ? <MinLoader margin={'0'} /> : <Svg.InfoCircle />}
                                        <p>Claim Listing</p>
                                    </>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
