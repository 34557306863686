import {
    SIGN_UP,
    LOG_IN,
    LOG_OUT,
    GET_RECOVERY_LINK,
    CHANGE_PASSWORD_REQUEST,
    RESET_PASSWORD,
    GET_MY_PROFILE,
    DELETE_ACCOUNT_REQUEST,
    EDIT_MY_ACCOUNT,
    INVITE_USER,
    SEARCH, GET_MY_ORGS,
} from './auth.types';

export const signUp = (body) => {
    return {
        type: SIGN_UP,
        payload: { body },
    };
};

export const logIn = (user) => {
    return {
        type: LOG_IN,
        payload: user,
    };
};

export const logOut = () => {
    return {
        type: LOG_OUT,
    };
};

export const getMyProfile = () => {
    return {
        type: GET_MY_PROFILE,
    };
};

export const getRecoveryLink = (email) => {
    return {
        type: GET_RECOVERY_LINK,
        payload: { email },
    };
};

export const resetPassword = (passwords) => {
    return {
        type: RESET_PASSWORD,
        payload: { passwords },
    };
};

export const changePassword = (data) => {
    return {
        type: CHANGE_PASSWORD_REQUEST,
        payload: { data },
    };
};

export const editAccount = (data) => {
    return {
        type: EDIT_MY_ACCOUNT,
        payload: { data },
    };
};

export const deleteAccount = (id) => {
    return {
        type: DELETE_ACCOUNT_REQUEST,
        payload: {
            id,
        },
    };
};

export const inviteUser = (params) => {
    return {
        type: INVITE_USER,
        payload: params,
    };
};

export const search = (params) => {
    return {
        type: SEARCH,
        payload: params,
    };
};
export const getMyOrgs = () => {
    return {
        type: GET_MY_ORGS,
    };
};
