export {
    initAxiosInterceptors,
    FindLoad,
    FindSuccess,
    FindError,
    useWindowSize,
    useWindowDimensions,
    SaveParams,
    SendPageSave,
    Notify,
    RenderScroll,
    ProfileInfo,
} from './hooks';
export { ModalProvider, Modals, useModal } from './context';
export { EmailValidator, PasswordValidator, PasswordValidator2 } from './validators';
export { originalDateConverter, dateConverter, multiConverter, startEndTime } from './dateConverter';
export { searchedTypes, renderLink, renderSearchIcon } from './searchBox';
export const token = localStorage.getItem('access-token');
export const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
