import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MinLoader, TextRow } from 'components';
import { Svg } from 'assets';
import { organizationActions } from 'store';
import { FindLoad, ProfileInfo, useModal, userInfo } from 'utils';
import { LogIn } from 'fragments';
import { FOLLOW, UN_FOLLOW } from './constants';

export const OrganizedBy = () => {
    const { event, org, orgSocials, myFollows } = useSelector((state) => ({
        event: state.events.eventById,
        org: state.orgs.organizationsById,
        orgSocials: state.orgs.orgSocials,
        myFollows: state.orgs.myFollows,
    }));

    const dispatch = useDispatch();
    const name = event?.org ? event.org.name : event?.creator ? event?.creator?.fullName : event?.creator === null && 'Deleted User';
    const { openModal } = useModal();
    const myFollowsInfo = myFollows?.find((i) => i?.userId === userInfo.id && i.orgId === org?.id);
    const followLoader = FindLoad(FOLLOW);
    const unFollowLoader = FindLoad(UN_FOLLOW);
    const { token } = ProfileInfo();

    const followOrg = () => {
        if (token) {
            const date = {
                org: event?.org?.id,
            };
            if (myFollowsInfo?.id) {
                dispatch(organizationActions.unfollow(event?.org?.id));
            } else {
                dispatch(organizationActions.follow(date));
            }
        } else {
            openModal(<LogIn />);
        }
    };


    return (
        <div className='details-white-box'>
            <div>
                <p className='detail-title'>Organized by</p>
                <div className='organized-by-info'>
                    <div className='organized-by-box'>
                        <div className='organized-by-icon'>
                            {event?.creator?.avatar ? <img src={event?.creator?.avatar?.url} alt='icon' /> :
                                <Svg.NoOrganizationIconSvg />}
                        </div>
                        <div className='organized-by-name-and-info'>
                            <p className='organized-by-name'>
                                <TextRow name={name} />
                            </p>
                            <div className='org-information'>
                                {/*<p>107 past events</p>*/}
                                {/*<div />*/}
                                <p>{`${org?.followerCount ? org?.followerCount : 0} followers`}</p>
                            </div>
                        </div>
                    </div>

                    <button className={`${myFollowsInfo?.id ? 'following-btn' : 'follow-btn'}  `} onClick={followOrg}>
                        {followLoader || unFollowLoader ? (
                            <MinLoader margin={'0'} width='20px' height='20px' />
                        ) : (
                            <>
                                {myFollowsInfo?.id ? (
                                    <p>Following</p>
                                ) : (
                                    <>
                                        <Svg.Add />
                                        <p>Follow</p>
                                    </>
                                )}
                            </>
                        )}
                    </button>
                </div>

                <div className='space-between'>
                    {org?.email && (
                        <a className='contact-organizer' href={`mailto:${org?.email}`}>
                            <Svg.Messages className='contact-organizer-by-svg' />
                            <p style={{ color: '#181D27' }}>Contact Organizer</p>
                        </a>
                    )}

                    <div className='org-links-box'>
                        {orgSocials?.twitterLink && (
                            <NavLink to={orgSocials?.twitterLink} target='_blank'>
                                <Svg.Twitter />
                            </NavLink>
                        )}

                        {orgSocials?.linkedinLink && (
                            <NavLink to={orgSocials?.linkedinLink} target='_blank'>
                                <Svg.Linkedin1 />
                            </NavLink>
                        )}

                        {orgSocials?.facebookLink && (
                            <NavLink to={orgSocials?.facebookLink} target='_blank'>
                                <Svg.Facebook />
                            </NavLink>
                        )}

                        {orgSocials?.instagramLink && (
                            <NavLink to={orgSocials?.instagramLink} target='_blank'>
                                <Svg.Instagram />
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
