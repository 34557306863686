import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Images, Svg } from 'assets';
import { EventGridCard } from 'fragments';
import { DateSelector, MinLoader, NotYetScreen } from 'components';
import { eventActions } from 'store';
import { Notify, SaveParams } from 'utils';
import Carousel from 'react-multi-carousel';
import { eventsYouLikeResponse } from '../../eventDetails/fragments/constants';

export const UpcomingEvents = () => {
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const allEvents = useSelector((state) => state.events.events);
    const categories = useSelector((state) => state.events.categories);
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const catList = categories?.length ? ['ALL', ...categories?.slice(0, 3)] : ['ALL'];
    const [selectedTag, setSelectedTag] = useState('ALL');
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const info = location?.state;

    const getEvents = (params) => {
        dispatch(eventActions.getEvents(params));
    };

    useEffect(() => {
        dispatch(eventActions.getCategories());
    }, [dispatch]);

    useEffect(() => {
        const params = {
            ...info,
        };
        if (selectedTag && selectedTag !== 'ALL') {
            params.categories = [selectedTag];
        } else {
            delete params.categories;
        }
        dispatch(eventActions.getEvents(params));
    }, [dispatch, info, selectedTag]);


    const nearMe = () => {

        if (navigator.geolocation) {
            setLoad(true);
            SaveParams('/', navigate, {});
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    getEvents({ lat: position.coords.latitude, lng: position.coords.longitude });
                    setLoad(false);
                },
                () => {
                    Notify('Location access denied. Please enable');
                    setLoad(false);
                },
            );
        } else {
            Notify('Geolocation is not supported by this browser.');
        }
    };

    const handleOnChange = (previousSlide, { currentSlide, slidesToShow, totalItems }) => {
        setIsAtStart(currentSlide === 0);
        setIsAtEnd(currentSlide + slidesToShow >= totalItems);
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };


    return (
        <>
            {/*{!!allEvents?.events?.length && (*/}
            <div className="upcoming-events-section">
                <div className="upcoming-events-title">
                    <p className="home-section-title">Upcoming events</p>
                    <a>
                        <Svg.DownArrow />
                    </a>
                    <button disabled={load} onClick={nearMe}>
                        {load ?
                            'Near Me...'
                            :
                            'Near Me'
                        }
                    </button>
                </div>
                {/*<p className="upcoming-events-sub-title">Discover exciting armenian events near you.</p>*/}
                <div className="tab-and-calendar">
                    <div className="tag-tabs-box">
                        {catList?.map((i, j) => (
                            <button
                                key={j}
                                onClick={() => setSelectedTag(i)}
                                className={selectedTag === i ? 'active-tab' : 'passive-tab'}>
                                {i}
                            </button>
                        ))}
                    </div>
                    <DateSelector link={'/'} />
                </div>
                <div>

                </div>
                {allEvents?.events?.length ?
                    <div className="upcoming-events-box">
                        <button
                            className={`slide-arrow-button slide-arrow-button_left ${isAtStart ? 'disabled-slide-arrow-button' : ''}`}
                            onClick={handlePrev}
                            disabled={isAtStart}
                        >
                            <Svg.ArrowLeftSlide />
                        </button>
                        <Carousel
                            ref={carouselRef}
                            responsive={eventsYouLikeResponse}
                            arrows={false}
                            afterChange={handleOnChange}
                        >
                            {allEvents?.events?.map((item, j) => (
                                <div key={j}>
                                    <EventGridCard
                                        slide={true}
                                        boxShadow="0px -8px 80px 0px rgba(0, 0, 0, 0.07), 0px -2.92px 29.201px 0px rgba(0, 0, 0, 0.05), 0px -1.418px 14.177px 0px rgba(0, 0, 0, 0.04), 0px -0.695px 6.95px 0px rgba(0, 0, 0, 0.03), 0px -0.275px 2.748px 0px rgba(0, 0, 0, 0.02)"
                                        event={item}
                                        detailBtn={true}
                                        containerClass="carousel-container"
                                        itemClass="carousel-item"
                                    />
                                </div>
                            ))}
                        </Carousel>
                        <button
                            className={`slide-arrow-button slide-arrow-button_right ${isAtEnd ? 'disabled-slide-arrow-button' : ''}`}
                            onClick={handleNext}
                            disabled={isAtEnd}
                        >
                            <Svg.ArrowRightSlide />
                        </button>
                    </div>
                    :

                    <NotYetScreen
                        image={Images.EmptyState}
                        title={'No Upcoming Events Yet'}
                        wrapperStyles={{minHeight: '400px', height: '400px'}}

                    />
                    // <div className="upcoming-events-not-yet">
                    //     <p>No Upcoming Events Yet</p>
                    // </div>
                }
                <div className="brows-all-block">
                    <button
                        className="brows-all-upcoming"
                        onClick={() => {
                            navigate('/events');
                        }}
                    >
                        Browse all events
                    </button>
                </div>
            </div>
            {/*)}*/}
        </>
    );
};
