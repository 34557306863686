/**Payment service */

import axios from 'axios';

export const paymentService = {
    assignCardService: (body) => axios.post(`/billing/pmtMethod`, { ...body }, { auth: true }),

    unAssignCardService: () => axios.delete('/billing/card', { auth: true }),

    getCurrentCardService: () => axios.get(`/billing/pmtMethod`, { auth: true }),

    getInvoicesService: () => axios.get(`/billing/invoices`, { auth: true }),
};
