import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { useLocation, useNavigate } from 'react-router';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { ButtonMain, CancelBtn, CheckboxMain } from 'components';
// import { ordersService } from 'store/orders/orders.service';
import { useDispatch } from 'react-redux';
import { httpRequestsOnSuccessActions, paymentActions } from 'store';
import { Svg } from 'assets';
import { FindSuccess, useModal } from 'utils';

export const CheckoutForm = () => {
    const ACTION_TYPE = 'ASSIGN_CARD';
    const stripe = useStripe();
    const elements = useElements();
    const [errors, setErrors] = useState({});
    const [iAccept, setIAccept] = useState(false);
    const [loader, setLoader] = useState(false);
    const { close } = useModal();
    const success = FindSuccess(ACTION_TYPE);
    // const location = useLocation();
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { state } = location;

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoader(true);
        setErrors({});

        if (!stripe || !elements) {
            return;
        }

        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                // billing_details: {
                //     name: event.target.name.value,
                // },
            });

            if (error) {
                switch (error.code) {
                    case 'incomplete_number':
                    case 'invalid_number':
                        setErrors((prev) => ({
                            ...prev,
                            cardNumber: 'Please enter a valid card number.',
                        }));
                        break;
                    case 'incomplete_expiry':
                    case 'invalid_expiry_year_past':
                    case 'invalid_expiry_year':
                    case 'invalid_expiry_month':
                        setErrors((prev) => ({
                            ...prev,
                            expiry: 'Please enter a valid expiry date.',
                        }));
                        break;
                    case 'incomplete_cvc':
                    case 'invalid_cvc':
                        setErrors((prev) => ({
                            ...prev,
                            cvc: 'Please enter a valid CVV.',
                        }));
                        break;
                    default:
                        setErrors((prev) => ({
                            ...prev,
                            els: error.message,
                        }));
                }
            } else {
                // const { data } = await ordersService.ordersPost({
                //     address: state?.address,
                //     email: state?.email,
                //     pmtMethod: paymentMethod?.id,
                // });

                dispatch(paymentActions.assignCard({ pmtMethod: paymentMethod?.id }));

                // navigate(`/report/${data?.id}`);
            }
        } catch (e) {
            setErrors((prev) => ({
                ...prev,
                els: e.data.message,
            }));
        } finally {
            setLoader(false);
        }
    };

    return (
        <form className="checkoutForm-wrapper" onSubmit={handleSubmit}>
            <div className="checkoutForm-svg">
                <Svg.CreditCardSvg />
            </div>

            <p className="add-card-title">Add Card</p>
            <p className="add-card-description">Fill in your card details to add a payment method.</p>

            <div className="checkoutForm-wrapper-box">
                <div className="name-number-wrapper">
                    <div className="credit-card-name">
                        <label className="checkoutForm-label" htmlFor="name">
                            Name on card
                            <input name="name" id="name" type="text" placeholder="Name Surname" required className="checkoutForm-input" />
                        </label>

                        <p className="card-errors">{errors?.name}</p>
                    </div>

                    <div className="credit-card-number">
                        <label className="checkoutForm-label" htmlFor="card">
                            Credit Card Number
                            <CardNumberElement
                                id={'card'}
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#181D27',
                                            '::placeholder': {
                                                color: '#717680',
                                            },
                                        },
                                        invalid: {
                                            color: 'red',
                                        },
                                    },
                                    classes: {
                                        base: 'checkoutForm-input',
                                        invalid: 'checkoutForm-input-error',
                                    },
                                    placeholder: 'xxxx xxxx xxxx xxxx',
                                }}
                            />
                            <p className="card-errors">{errors?.cardNumber}</p>
                        </label>
                    </div>
                </div>
                <div className="expiry-date-cvv">
                    <div className="credit-card-expiry-date">
                        <label className="checkoutForm-label" htmlFor="date">
                            Expiry Date
                            <CardExpiryElement
                                id={'date'}
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#181D27',
                                            '::placeholder': {
                                                color: '#717680',
                                            },
                                        },
                                        invalid: {
                                            color: '#1e2446',
                                        },
                                    },
                                    classes: {
                                        base: 'checkoutForm-input',
                                        invalid: 'checkoutForm-input-error',
                                    },
                                    placeholder: 'mm / yy',
                                }}
                            />
                        </label>

                        <p className="card-errors">{errors?.expiry}</p>
                    </div>

                    <div className="credit-card-cvv">
                        <label className="checkoutForm-label" htmlFor="cvv">
                            CVV
                            <CardCvcElement
                                id="cvv"
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#181D27',
                                            '::placeholder': {
                                                color: '#717680',
                                            },
                                        },
                                        invalid: {
                                            color: '#1e2446',
                                        },
                                    },
                                    classes: {
                                        base: 'checkoutForm-input',
                                        invalid: 'checkoutForm-input-error',
                                    },
                                    placeholder: '***',
                                }}
                            />
                        </label>

                        <p className="card-errors">{errors?.cvc}</p>
                    </div>
                </div>
            </div>

            <p className="card-errors">{errors?.els}</p>

            <div className="checkbox-text-block">
                <CheckboxMain
                    checked={iAccept}
                    onChange={() => {
                        setIAccept((prev) => !prev);
                    }}
                />
                <p className="checkbox-text">
                    I agree with the
                    <NavLink to="/terms" className="checkbox-text-link" target="_blank">
                        terms and conditions.
                    </NavLink>
                </p>
            </div>

            <div className="card-action-btns">
                <CancelBtn />
                <ButtonMain type="submit" text="Add" className="generate-report-button" disabled={!stripe || !iAccept} actionTye={ACTION_TYPE} load={loader} />
            </div>
        </form>
    );
};
