import { ReactComponent as Logo } from './images/svg/armat.svg';
import { ReactComponent as Search } from './images/svg/search.svg';
import { ReactComponent as Calendar } from './images/svg/calendar.svg';
import { ReactComponent as EventsNearSvg } from './images/svg/EventsNearSvg.svg';
import { ReactComponent as ArmenianBusinessesSvg } from './images/svg/ArmenianBusinessesSvg.svg';
import { ReactComponent as ArmenianOrganizationsSvg } from './images/svg/ArmenianOrganizationsSvg.svg';
import { ReactComponent as DownArrow } from './images/svg/downArrow.svg';
import { ReactComponent as EventLocation } from './images/svg/eventLocation.svg';
import { ReactComponent as EventCalendar } from './images/svg/eventCalendar.svg';
import { ReactComponent as BenefitCardIcon } from './images/svg/benefitCardIcon.svg';
import { ReactComponent as Messages } from './images/svg/messages.svg';
import { ReactComponent as BeckButton } from './images/svg/beckButton.svg';
import { ReactComponent as EventImages } from './images/svg/eventImages.svg';
import { ReactComponent as MoreActions } from './images/svg/moreActions.svg';
import { ReactComponent as Share } from './images/svg/share.svg';
import { ReactComponent as BuyTicket } from './images/svg/buyTicket.svg';
import { ReactComponent as ProfileUserCheck } from './images/svg/profileUserCheck.svg';
import { ReactComponent as User } from './images/svg/user.svg';
import { ReactComponent as UserNoImage } from './images/svg/userNoImage.svg';
import { ReactComponent as Users } from './images/svg/users.svg';
import { ReactComponent as People } from './images/svg/people.svg';
import { ReactComponent as OrganizationLogo } from './images/svg/organizationLogo.svg';
import { ReactComponent as Interested } from './images/svg/interested.svg';
import { ReactComponent as Going } from './images/svg/going.svg';
import { ReactComponent as NotGoing } from './images/svg/notGoing.svg';
import { ReactComponent as DownBtn } from './images/svg/downBtn.svg';
import { ReactComponent as LinkedIn } from './images/svg/linkedIn.svg';
import { ReactComponent as Twitter } from './images/svg/twitter.svg';
import { ReactComponent as Linkedin1 } from './images/svg/linkedin1.svg';
import { ReactComponent as Instagram } from './images/svg/instagram.svg';
import { ReactComponent as Facebook } from './images/svg/facebook.svg';
import { ReactComponent as GridView } from './images/svg/gridView.svg';
import { ReactComponent as ListView } from './images/svg/listView.svg';
import { ReactComponent as MapView } from './images/svg/mapView.svg';
import { ReactComponent as Lock } from './images/svg/lock.svg';
import { ReactComponent as Sms } from './images/svg/sms.svg';
import { ReactComponent as Close } from './images/svg/close.svg';
import { ReactComponent as Profile } from './images/svg/profile.svg';
import { ReactComponent as CallCalling } from './images/svg/callCalling.svg';
import { ReactComponent as Gallery } from './images/svg/gallery.svg';
import { ReactComponent as SendMessage } from './images/svg/sendMessage.svg';
import { ReactComponent as SendUsMessages } from './images/svg/sendUsMessages.svg';
import { ReactComponent as OnlineEvent } from './images/svg/onlineEvent.svg';
import { ReactComponent as Add } from './images/svg/add.svg';
import { ReactComponent as NoOrganizationIconSvg } from './images/svg/noOrganizationIcon.svg';
import { ReactComponent as NoOrganizationIcon2Svg } from './images/svg/noOrganizationIcon2.svg';
import { ReactComponent as Export } from './images/svg/export.svg';
import { ReactComponent as Follow } from './images/svg/follow.svg';
import { ReactComponent as InfoCircle } from './images/svg/infoCircle.svg';
import { ReactComponent as Edit } from './images/svg/edit.svg';
import { ReactComponent as OrgCalendar } from './images/svg/orgCalendar.svg';
import { ReactComponent as Visa } from './images/svg/visa.svg';
import { ReactComponent as Master } from './images/svg/master.svg';
import { ReactComponent as DocumentDownload } from './images/svg/documentDownload.svg';
import { ReactComponent as EyeSvg } from './images/svg/EyeSvg.svg';
import { ReactComponent as EyeSlashSvg } from './images/svg/EyeSlashSvg.svg';
import { ReactComponent as SocialFacebook } from './images/svg/socialFacebook.svg';
import { ReactComponent as SocialGoogle } from './images/svg/socialGoogle.svg';
import { ReactComponent as SocialInstagram } from './images/svg/socialInstagram.svg';
import { ReactComponent as SocialYelp } from './images/svg/socialYelp.svg';
import { ReactComponent as SocialYoutube } from './images/svg/socialYoutube.svg';
import { ReactComponent as SocialTwitter } from './images/svg/socialTwitter.svg';
import { ReactComponent as Star } from './images/svg/star.svg';
import { ReactComponent as ArrowRightLine } from './images/svg/arrowRightLine.svg';
import { ReactComponent as ArrowLeftLineSvg } from './images/svg/ArrowLeftLineSvg.svg';
import { ReactComponent as Download } from './images/svg/download.svg';
import { ReactComponent as Graphic } from './images/svg/graphicIcon.svg';
import { ReactComponent as UploadOrgUpload } from './images/svg/uploadOrgUpload.svg';
import { ReactComponent as TrashBlack } from './images/svg/trashBlack.svg';
import { ReactComponent as TrashRed } from './images/svg/trashRed.svg';
import { ReactComponent as Luggage } from './images/svg/luggage.svg';
import { ReactComponent as Compassion } from './images/svg/compassion.svg';
import { ReactComponent as Notification } from './images/svg/notification.svg';
import { ReactComponent as BurgerMenu } from './images/svg/burgerMenu.svg';
import { ReactComponent as CloseBurger } from './images/svg/closeBurger.svg';
import { ReactComponent as DiscoveredEvents } from './images/svg/discoveredEvents.svg';
import { ReactComponent as FindBusiness } from './images/svg/findBusiness.svg';
import { ReactComponent as SupportOrg } from './images/svg/supportOrg.svg';
import { ReactComponent as SignOut } from './images/svg/signOut.svg';
import { ReactComponent as Setting } from './images/svg/setting.svg';
import { ReactComponent as NotificationIcon } from './images/svg/notificationIcon.svg';
import { ReactComponent as Privacy } from './images/svg/privacy.svg';
import { ReactComponent as ProfileDelete } from './images/svg/profileDelete.svg';
import { ReactComponent as UserName } from './images/svg/userName.svg';
import { ReactComponent as UserPlus } from './images/svg/userPlus.svg';
import { ReactComponent as DashboardView } from './images/svg/accounts/dashboardView.svg';
import { ReactComponent as MyProfile } from './images/svg/accounts/profile.svg';
import { ReactComponent as UserView } from './images/svg/accounts/userView.svg';
import { ReactComponent as EventsView } from './images/svg/accounts/eventsView.svg';
import { ReactComponent as MyTickets } from './images/svg/accounts/myTickets.svg';
import { ReactComponent as MySubscriptions } from './images/svg/accounts/mySubscriptions.svg';
import { ReactComponent as Billing } from './images/svg/accounts/billing.svg';
import { ReactComponent as InvitePeople } from './images/svg/accounts/invitePeople.svg';
import { ReactComponent as CheckIconSvg } from './images/svg/accounts/CheckIconSvg.svg';
import { ReactComponent as LogOut } from './images/svg/accounts/logOut.svg';
import { ReactComponent as ForgotSvg } from './images/svg/accounts/ForgotSvg.svg';
import { ReactComponent as SignUpSvg } from './images/svg/accounts/SignUpSvg.svg';
import { ReactComponent as LogInSvg } from './images/svg/accounts/LogInSvg.svg';
import { ReactComponent as CheckEmailSvg } from './images/svg/accounts/CheckEmailSvg.svg';
import { ReactComponent as ResetLockSvg } from './images/svg/accounts/ResetLockSvg.svg';
import { ReactComponent as resetSuccessSvg } from './images/svg/accounts/resetSuccessSvg.svg';
import { ReactComponent as Password } from './images/svg/password.svg';
import { ReactComponent as InAppNotification } from './images/svg/inAppNotification.svg';
import { ReactComponent as NotificationStatus } from './images/svg/notificationStatus.svg';
import { ReactComponent as SmsNotification } from './images/svg/smsNotification.svg';
import { ReactComponent as Warning } from './images/svg/warning.svg';
import { ReactComponent as AccountEmail } from './images/svg/accountEmail.svg';
import { ReactComponent as AccountPhone } from './images/svg/accountPhone.svg';
import { ReactComponent as ClaimedIcon } from './images/svg/claimedIcon.svg';
import { ReactComponent as BookCta } from './images/svg/cta/bookCta.svg';
import { ReactComponent as ContactCta } from './images/svg/cta/contactCta.svg';
import { ReactComponent as DonateCta } from './images/svg/cta/donateCta.svg';
import { ReactComponent as RegisterCta } from './images/svg/cta/registerCta.svg';
import { ReactComponent as SendEmailCta } from './images/svg/cta/sendEmailCta.svg';
import { ReactComponent as RightFrame } from './images/svg/rightFrame.svg';
import { ReactComponent as Location } from './images/svg/location.svg';
import { ReactComponent as DateArrowRight } from './images/svg/dateArrowRight.svg';
import { ReactComponent as Done } from './images/svg/done.svg';
import { ReactComponent as RemoveWhite } from './images/svg/removeWhite.svg';
import { ReactComponent as Celebration } from './images/svg/celebration.svg';
import { ReactComponent as Invite } from './images/svg/invite.svg';
import { ReactComponent as InviteSuccessSvg } from './images/svg/InviteSuccessSvg.svg';
import { ReactComponent as CallBlack } from './images/svg/callBlack.svg';
import { ReactComponent as SmsBlack } from './images/svg/smsBlack.svg';
import { ReactComponent as Business } from './images/svg/business.svg';
import { ReactComponent as Event } from './images/svg/event.svg';
import { ReactComponent as Listing } from './images/svg/listing.svg';
import { ReactComponent as CurrentLocation } from './images/svg/currentlocation.svg';
import { ReactComponent as NotificationDone } from './images/svg/notifications/notificationDone.svg';
import { ReactComponent as NoteDisapprovedSvg } from './images/svg/notifications/NoteDisapprovedSvg.svg';
import { ReactComponent as CongratsOrgSvg } from './images/svg/notifications/CongratsOrgSvg.svg';
import { ReactComponent as CongratsMemberSvg } from './images/svg/notifications/CongratsMemberSvg.svg';
import { ReactComponent as SearchRedIon } from './images/svg/searchRedIon.svg';
import { ReactComponent as StopBtn } from './images/svg/stopBtn.svg';
import { ReactComponent as CreditCard } from './images/svg/creditCard.svg';
import { ReactComponent as TicketDone } from './images/svg/ticketDone.svg';
import { ReactComponent as ArrowUpRight } from './images/svg/ArrowUpRight.svg';
import { ReactComponent as MapPinSvg } from './images/svg/MapPinSvg.svg';
import { ReactComponent as ArrowDownSelect } from './images/svg/arrowDownSelect.svg';
import { ReactComponent as PlusSvg } from './images/svg/PlusSvg.svg';
import { ReactComponent as UploadCloudSvg } from './images/svg/UploadCloudSvg.svg';
import { ReactComponent as ArrowAccordionDown } from './images/svg/ArrowAccordionDown.svg';
import { ReactComponent as MasterAcceptSvg } from './images/svg/MasterAcceptSvg.svg';
import { ReactComponent as VisaAcceptSvg } from './images/svg/VisaAcceptSvg.svg';
import { ReactComponent as CreditCardSvg } from './images/svg/CreditCardSvg.svg';
import { ReactComponent as SendSvg } from './images/svg/SendSvg.svg';
import { ReactComponent as TwitterFSvg } from './images/svg/TwitterFSvg.svg';
import { ReactComponent as LinkedInFSvg } from './images/svg/LinkedInFSvg.svg';
import { ReactComponent as FacebookFSvg } from './images/svg/FacebookFSvg.svg';
import { ReactComponent as InstagramFSvg } from './images/svg/InstagramFSvg.svg';
import { ReactComponent as BenefitSvg } from './images/svg/BenefitSvg.svg';
import { ReactComponent as SuggestSvg } from './images/svg/SuggestSvg.svg';
import { ReactComponent as PlusCircleSvg } from './images/svg/PlusCircleSvg.svg';
import { ReactComponent as PlaySvg } from './images/svg/PlaySvg.svg';
import { ReactComponent as ArrowRightSlide } from './images/svg/ArrowRightSlide.svg';
import { ReactComponent as ArrowLeftSlide } from './images/svg/ArrowLeftSlide.svg';
import { ReactComponent as EmptyNotifications } from './images/svg/EmptyNotifications.svg';
import { ReactComponent as Globe } from './images/svg/globe.svg';
import DropdownArrow from './images/svg/dropdownArrow.svg';
import OrgFacebook from './images/svg/orgFacebook.svg';
import OrgGoogle from './images/svg/orgGoogle.svg';
import OrgInstagram from './images/svg/orgInstagram.svg';
import OrgWWW from './images/svg/orgWWW.svg';
import OrgYelp from './images/svg/orgYelp.svg';
import OrgTwitter from './images/svg/orgTwitter.svg';
import OrgYoutube from './images/svg/orgYoutube.svg';
import NoOrganizationIcon from './images/svg/noOrganizationIcon.svg';
import NoImageGallery from './images/svg/gallery.svg';
import SendUsMessagesImg from './images/svg/sendUsMessages.svg';
import MapMarker from './images/svg/mapMarker.svg';
import RedMapMarker from './images/svg/redMapMarker.svg';
import SelectDown from './images/svg/selectDown.svg';
import Banner from './images/img/banner.png';
import EventImage from './images/img/eventImage.png';
import BusinessImage from './images/img/businessImage.png';
import OrgImage from './images/img/orgImage.png';
import Benefit from './images/img/benefit.png';
import Footer from './images/img/footer.png';
import ArmenianOrgs from './images/img/armenianOrgs.png';
import DefaultImage from './images/img/defaultImage.png';
import UploadImage from './images/img/uploadImage.png';
import EmptyState from './images/img/emptyState.png';
import EmptyOrg from './images/img/emptyOrg.png';
import EmptySearch from './images/img/emptySearch.png';
import EmptyTickets from './images/img/emptyTickets.png';
import EmptyInvoices from './images/img/emptyInvoices.png';
import LogoImage from './images/img/logoImage.png';
import headerBannerMap from './images/img/headerBannerMap.png';

export const Images = {
    LogoImage,
    EmptyInvoices,
    EmptyTickets,
    EmptySearch,
    EmptyState,
    EmptyOrg,
    UploadImage,
    DefaultImage,
    ArmenianOrgs,
    Banner,
    EventImage,
    BusinessImage,
    OrgImage,
    Benefit,
    Footer,
    SelectDown,
    headerBannerMap
};

export const Svg = {
    Globe,
    ArrowRightSlide,
    EmptyNotifications,
    ArrowLeftSlide,
    SuggestSvg,
    PlaySvg,
    PlusCircleSvg,
    TwitterFSvg,
    LinkedInFSvg,
    FacebookFSvg,
    InstagramFSvg,
    SendSvg,
    EventsNearSvg,
    ArmenianBusinessesSvg,
    ArmenianOrganizationsSvg,
    CreditCardSvg,
    VisaAcceptSvg,
    MasterAcceptSvg,
    ArrowAccordionDown,
    UploadCloudSvg,
    TicketDone,
    CreditCard,
    StopBtn,
    SearchRedIon,
    NotificationDone,
    NoteDisapprovedSvg,
    CongratsOrgSvg,
    CongratsMemberSvg,
    CurrentLocation,
    Listing,
    Event,
    Business,
    CallBlack,
    SmsBlack,
    Invite,
    InviteSuccessSvg,
    Celebration,
    RemoveWhite,
    Done,
    DateArrowRight,
    Location,
    DropdownArrow,
    ArrowUpRight,
    RightFrame,
    BookCta,
    ContactCta,
    DonateCta,
    RegisterCta,
    SendEmailCta,
    ClaimedIcon,
    AccountPhone,
    AccountEmail,
    Warning,
    InAppNotification,
    NotificationStatus,
    SmsNotification,
    Password,
    CheckIconSvg,
    LogOut,
    SignUpSvg,
    LogInSvg,
    ForgotSvg,
    CheckEmailSvg,
    ResetLockSvg,
    resetSuccessSvg,
    InvitePeople,
    Billing,
    MyTickets,
    MySubscriptions,
    MyProfile,
    UserView,
    UserNoImage,
    UserPlus,
    EventsView,
    DashboardView,
    UserName,
    Setting,
    NotificationIcon,
    Privacy,
    ProfileDelete,
    SignOut,
    SupportOrg,
    FindBusiness,
    DiscoveredEvents,
    CloseBurger,
    BurgerMenu,
    Notification,
    Compassion,
    Luggage,
    OrgFacebook,
    OrgGoogle,
    OrgInstagram,
    OrgWWW,
    OrgYelp,
    OrgTwitter,
    OrgYoutube,
    TrashBlack,
    TrashRed,
    UploadOrgUpload,
    Graphic,
    Download,
    ArrowRightLine,
    ArrowLeftLineSvg,
    Star,
    SocialTwitter,
    SocialFacebook,
    SocialGoogle,
    SocialInstagram,
    SocialYelp,
    SocialYoutube,
    EyeSvg,
    EyeSlashSvg,
    DocumentDownload,
    Visa,
    Master,
    OrgCalendar,
    Edit,
    InfoCircle,
    Follow,
    Export,
    NoOrganizationIconSvg,
    NoOrganizationIcon2Svg,
    NoOrganizationIcon,
    Add,
    OnlineEvent,
    SendUsMessagesImg,
    SendUsMessages,
    SendMessage,
    RedMapMarker,
    MapPinSvg,
    ArrowDownSelect,
    PlusSvg,
    MapMarker,
    Gallery,
    NoImageGallery,
    CallCalling,
    Profile,
    Close,
    Lock,
    Sms,
    MapView,
    ListView,
    GridView,
    Instagram,
    Facebook,
    Twitter,
    Linkedin1,
    LinkedIn,
    DownBtn,
    NotGoing,
    Going,
    Interested,
    OrganizationLogo,
    People,
    User,
    Users,
    ProfileUserCheck,
    BuyTicket,
    Share,
    MoreActions,
    EventImages,
    BeckButton,
    Logo,
    Search,
    Calendar,
    DownArrow,
    EventLocation,
    EventCalendar,
    BenefitCardIcon,
    Messages,
    BenefitSvg,
};
