import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { Svg, Images } from 'assets';
import { ORG_TYPES } from 'fragments';
import { useWindowSize } from 'utils';
import { organizationActions } from 'store';


export const SupportOrgs = () => {
    const [progress, setProgress] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [index, setIndex] = useState(0);
    const { organizations } = useSelector((state) => state.orgs);
    const dispatch = useDispatch();
    const [width] = useWindowSize();
    const navigate = useNavigate();
    const refTimer = useRef();
    const currentOrg = organizations?.orgs?.[index];

    useEffect(() => {
        const params = {
            type: ORG_TYPES.NON_PROFIT,
            limit: 10,
        };
        dispatch(organizationActions.getOrg(params));
    }, [dispatch]);



    useEffect(() => {
        const transitionTime = 5000;
        const progressUpdateInterval = 50;

        if (isRunning) {
            let startTime = Date.now();

            refTimer.current = window.setInterval(() => {
                const elapsedTime = Date.now() - startTime;

                setProgress(Math.min((elapsedTime / transitionTime) * 100, 100));

                if (elapsedTime >= transitionTime) {
                    setIndex((prevIndex) => (prevIndex === organizations?.orgs?.length - 1 ? 1 : prevIndex + 1));
                    startTime = Date.now();
                    setProgress(0);
                }
            }, progressUpdateInterval);
        }

        return () => {
            window.clearInterval(refTimer.current);
        };
    }, [isRunning, index]);


    const toggleTimer = () => {
        setIsRunning((prev) => !prev);

        if (isRunning) {
            setProgress(100);
            window.clearInterval(refTimer.current);
        } else {
            setProgress(0);
        }
    };

    const clickToNextOrPrev = (prop) => {
        if (prop === 'next') {
            setProgress(0);
            window.clearInterval(refTimer.current);
            setIndex((prevIndex) => (prevIndex === organizations?.orgs?.length - 1 ? 0 : prevIndex + 1));
        } else {
            setProgress(0);
            window.clearInterval(refTimer.current);
            setIndex((prevIndex) => (prevIndex === 0 ? organizations?.orgs?.length - 1 : prevIndex - 1));
        }
    };

    return (
        <div className='support-arm-organization'>
            <div className='container'>
                <div className='support-arm-header'>
                    <p className='home-section-title'>
                        {width > 768 ? 'Support Armenian Organizations' : 'Organizations'}
                    </p>

                    <div className='flex-end'>
                        <div className='view-all-btn'>
                            <NavLink to={'/nonprofits'}>
                                {width > 768 ? 'Browse All Organizations' : 'Browse All'}

                                <Svg.ArrowUpRight />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className='support-org-slider'>
                    <div className='support-org-card'>
                        <div className='org-supported-slider-wrapper-box'
                             style={{ backgroundImage: `url(${currentOrg?.images?.[currentOrg?.images?.mainImage || 0]?.url || currentOrg?.avatar?.url || Images.OrgImage})` }}
                        >

                            <div className='org-slider-types-box'>
                                <div className='slider-type'>
                                    <LinearProgress color='inherit' variant='determinate' value={progress} />
                                </div>

                                <button onClick={toggleTimer}>
                                    {isRunning ? <Svg.StopBtn /> : <Svg.PlaySvg />}
                                </button>
                            </div>
                        </div>
                        <div className='supprot-org-info'>
                            <div className='supprot-org-info-title_svg'>
                                {currentOrg?.name &&
                                    <p className='support-org-title'>{currentOrg?.name}</p>
                                }

                                <button
                                    className='supprot-org-info-button_svg'
                                    onClick={() => {
                                        navigate(`/organization/${currentOrg?.id}`);
                                    }}
                                >
                                    <Svg.ArrowUpRight />
                                </button>
                            </div>

                            {currentOrg?.description &&
                                <p className="support-org-desc" dangerouslySetInnerHTML={{ __html: currentOrg?.description }} />
                            }


                            <div className='support-org-categories-wrapper'>
                                {currentOrg?.categories?.map((item, i) => (
                                    <div key={i} className='support-org-categories'>
                                        <div>{item?.text}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='support-org-footer'>
                    <button onClick={() => clickToNextOrPrev()}>
                        <Svg.ArrowLeftLineSvg />
                        Previous
                    </button>

                    <button onClick={() => clickToNextOrPrev('next')}>
                        Next
                        <Svg.DateArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};
